import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { Col, Container, Row } from "react-bootstrap";

//
import { changeTopNavSwiperState } from "../../redux/features/theme/slice";
import { getHomeInfo } from "../../redux/features/main/actions";

//
import MainSlider from "../../components/dashboard/MainSlider";
import MiddleBanners from "../../components/dashboard/MiddleBanners";
import TitleSwiper from "../../components/globals/TitleSwiper";
import AdBanners from "../../components/dashboard/AdBanners";

import MetaDecorator from "../../components/globals/MetaDecorator";
import DashboardLoading from "../../components/dashboard/DashboardLoading";
import Product from "../../components/category/Product";
import BlogItems from "../../components/dashboard/BlogItems";

function Dashboard({ changeTopNavSwiperState, configs }) {
  const [seo, set_seo] = useSeo();

  // Home
  const [homeInfo, setHomeInfo] = useState([]);
  const [homeLoading, setHomeLoading] = useState(false);

  /**
   * Get Home Info
   */
  const get_home_info = async () => {
    // start laoding
    setHomeLoading(true);

    // request
    await getHomeInfo().then((res) => {
      if (res) {
        setHomeInfo(res);
      }
    });

    // stop loading
    setHomeLoading(false);
  };

  useEffect(() => {
    changeTopNavSwiperState(true);
    get_home_info();

    return () => {
      changeTopNavSwiperState(false);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configs) {
      set_seo({
        title: configs.SEO_TITLE,
        description: configs.SEO_DESCRIPTION,
        canonical: configs.SEO_CANONICAL,
        keywords: configs.SEO_KEYWORDS,
      });
    }
  }, [configs]);

  return (
    <div id="dashboard">
      <MetaDecorator seo={seo} />

      {homeLoading ? (
        <DashboardLoading />
      ) : (
        <>
          {homeInfo.map((item, index) => (
            <>
              {item.theme === "BANNER_SCROLLING_1" && (
                <MainSlider fix items={item.data.items} />
              )}

              {item.theme === "SITE_HEADER_BANNER_1" && (
                <Container>
                  <Row>
                    <Col xl={8}>
                      <MainSlider items={item.data.scrolling_banner.items} />
                    </Col>

                    <Col xl={4} className="d-none d-md-block mt-4 mt-xl-0">
                      <Row className="flex-md-row flex-xl-column justify-content-between h-100">
                        {item.data.side_products.map((item) => (
                          <Col xs={6} xl={12}>
                            <Product item={item} key={item.id} horizontal />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              )}

              {item.theme === "SITE_ADS_2" && (
                <Container>
                  <MiddleBanners items={item.data.items} />
                </Container>
              )}

              {item.theme === "SITE_PRODUCT_SHORT_1" && (
                <Container>
                  <TitleSwiper text={item.title} items={item.data.items} />
                </Container>
              )}

              {item.theme === "SITE_ADS_1" && (
                <Container>
                  <AdBanners items={item.data.items} />
                </Container>
              )}

              {item.theme === "BLOG_POSTS_1" && (
                <Container>
                  <BlogItems items={item.data.items} />
                </Container>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
}

Dashboard.propTypes = {
  changeTopNavSwiperState: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  changeTopNavSwiperState,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
