import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Breadcrumb({ array, home = true, main = "app" }) {
  const createLink = (main, item) => {
    if (item.type != "search") {
      return `/${main}/${item.type}/${item.id}`;
    } else {
      return `/${main}/${item.type}?category_id=${item.id}`;
    }
  };
  return (
    <div className="breadcrumb">
      <p className="truncate">
        {home && <Link to="/">خانه</Link>}
        {array.map((item) => (
          <Link to={createLink(main, item)} key={item.id}>
            {item.title}
          </Link>
        ))}
      </p>
    </div>
  );
}

Breadcrumb.propTypes = {
  array: PropTypes.array.isRequired,
  main: PropTypes.string,
};

export default Breadcrumb;
