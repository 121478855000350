import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const { origin } = window.location;

const envBaseURL = process.env.REACT_APP_BASE_URL_API;
const baseURL = envBaseURL.includes("http")
  ? envBaseURL
  : `${origin}/${envBaseURL}`;

const mainApi = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "content-language": "fa",
  },
});

mainApi.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    let cartSession = localStorage.getItem("cartSession");
    let selectedCurrency = JSON.parse(
      localStorage.getItem("selectedCurrency") || "{}"
    );
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    if (cartSession) {
      config.headers.common["X-Cart-ID"] = cartSession;
    }
    if (selectedCurrency.id) {
      config.headers.common["x-currency"] = selectedCurrency.id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
mainApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error.response);
    let status = error.response.status;

    let message = error.response.data.message;

    if (typeof message === "string") {
      toast.error(message, {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"> </i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons"></i>
        ),
      });
    } else {
      for (const [key, value] of Object.entries(message)) {
        if (typeof value === "string") {
          toast.error(value, {
            theme: "colored",
            icon: <i className="fas fa-bell toast_icons"> </i>,
            closeButton: (
              <i className="fas fa-times-circle toast_close_button toast_icons"></i>
            ),
          });
        } else {
          for (let i = 0; i < value.length; i++) {
            const element = value[i];

            toast.error(element, {
              theme: "colored",
              icon: <i className="fas fa-bell toast_icons"> </i>,
              closeButton: (
                <i className="fas fa-times-circle toast_close_button toast_icons"></i>
              ),
            });
          }
        }
      }
    }

    switch (status) {
      case 401:
        let pathName = window.location.pathname;
        localStorage.removeItem("token");
        if (pathName.includes("panel")) {
          window.location = `/auth/login?redirect=${pathName}`;
        }

        break;

      case 422:
        console.log("12");
        // localStorage.setItem('path' , '/subscription/pricing')
        break;
      default:
        break;
    }

    return Promise.reject(error);
  }
);

export default mainApi;
