import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

function MainSlider({ items, fix = false }) {
  return (
    <div className={`main-slider ${fix ? "fix" : ""}`}>
      <Swiper
        // autoplay={{
        //   delay: 10000,
        //   disableOnInteraction: false,
        // }}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            {item.url.includes("http") ? (
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img src={item.image} alt="slider" />
              </a>
            ) : (
              <Link
                to={item.url.charAt(0) == "/" ? item.url : `/${item.url}`}
                className="d-flex"
              >
                <img src={item.image} alt="slider" />
              </Link>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

MainSlider.propTypes = {
  items: PropTypes.array.isRequired,
  flat: PropTypes.bool,
};

export default MainSlider;
