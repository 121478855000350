import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Col, Row } from "react-bootstrap";

//
import { getQoutes } from "../../redux/features/user/actions";
import { addToCart } from "../../redux/features/cart/actions";

//
import QouteItem from "../../components/panel/QouteItem";
import NothingToShow from "../../components/globals/NothingToShow";
import BoxLoading from "../../components/globals/BoxLoading";
import Pagination from "../../components/globals/Pagination";
import MobileQouteItem from "../../components/panel/MobileQouteItem";
import MetaDecorator from "../../components/globals/MetaDecorator";

function QuotesList({ windowSize }) {
  let navigate = useNavigate();
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [qoutes, setQoutes] = useState({});

  /**
   * Get Qoutes
   */
  const get_qoutes = async (page) => {
    // start loading
    setLoading(true);

    // request
    await getQoutes(page).then((res) => {
      if (res) {
        setQoutes(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  /**
   * Add To Cart
   */
  const add_to_cart = async (quote_id) => {
    // request
    await addToCart({ quote_id }).then((res) => {
      if (res) {
        navigate("/cart");
      }
    });
  };

  useEffect(() => {
    set_seo({}, "استعلامات");
    get_qoutes(1);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />
      <div id="qoutes">
        <div className="page-title mb-3 mt-5 mt-md-3">لیست استعلامات</div>
        {qoutes.data && qoutes.data.length ? (
          <>
            {windowSize > 768 ? (
              <Card className="dark-border">
                <Card.Body className="panel-item-box">
                  <table>
                    <thead>
                      <tr>
                        <th width="15%"></th>
                        <th width="20%"></th>
                        <th width="20%"></th>
                        <th width="20%"></th>
                        <th width="10%"></th>
                        <th width="5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {qoutes.data.map((item) => (
                        <QouteItem
                          item={item}
                          key={item.id}
                          add_to_cart={add_to_cart}
                        />
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            ) : (
              <Row>
                {qoutes.data.map((item) => (
                  <Col sm={4} key={item.id} className="gutter-b">
                    <MobileQouteItem item={item} add_to_cart={add_to_cart} />
                  </Col>
                ))}
              </Row>
            )}
          </>
        ) : (
          <Card className="dark-border">
            <Card.Body>
              <NothingToShow text="استعلامی برای نمایش وجود ندارد" />
              {loading && <BoxLoading />}
            </Card.Body>
          </Card>
        )}

        {qoutes.last_page && qoutes.last_page > 1 && (
          <Pagination
            currentPage={qoutes.current_page - 1}
            lastPage={qoutes.last_page}
            changePage={(page) => get_qoutes(page.selected + 1)}
          />
        )}
      </div>
    </>
  );
}

QuotesList.propTypes = {
  windowSize: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(QuotesList);
