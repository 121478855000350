import React from "react";
import BlogItem from "../blog/BlogItem";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function BlogItems({ items }) {
  return (
    <div className="blog-box">
      <Swiper
        slidesPerView={1.2}
        spaceBetween={24}
        breakpoints={{
          400: {
            slidesPerView: 1.8,
          },
          500: {
            slidesPerView: 2.2,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3.2,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <BlogItem key={item.id} item={item} big />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default BlogItems;
