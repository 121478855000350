import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import { Offcanvas } from "react-bootstrap";

function HeaderOffconvas({ categories, configs }) {
  const [show, setShow] = useState(false);

  const toggleClass = (id) => {
    let element = document.getElementById(id);

    element.classList.toggle("active");
  };

  return (
    <>
      <i
        className="fas fa-bars offconvas-icon"
        onClick={() => setShow(true)}
      ></i>

      <Offcanvas
        id="header-offconvas"
        show={show}
        placement="end"
        onHide={() => setShow(false)}
      >
        <Offcanvas.Header closeButton />

        <Offcanvas.Body>
          <div className="left-panel v2">
            <div>
              <ul className="top-links">
                <li className="d-flex align-items-center">
                  <span className="pulse"></span>{" "}
                  <a href={`tel:${configs.CO_TELEPHONE}`} className='d-flex'>
                    <b className="mx-1">مشاوره رایگان</b> <b className="">{configs.CO_TELEPHONE}</b>
                  </a>
                </li>
              </ul>

              {!!categories.length && (
                <ul className="cat-links">
                  {categories.map((cat) => (
                    <li id={cat.id} key={cat.id}>
                      <span onClick={() => toggleClass(cat.id)}>
                        {cat.title}
                      </span>
                      <ul>
                        <li>
                          <Link
                            className="view-all-menu text-primary"
                            to={`/category-info/${cat.id}`}
                            onClick={() => setShow(false)}
                          >
                            مشاهده همه
                          </Link>
                        </li>
                        {cat.children &&
                          cat.children.map((subCat) => (
                            <li id={subCat.id} key={subCat.id}>
                              <span onClick={() => toggleClass(subCat.id)}>
                                {subCat.title}
                              </span>
                              <ul>
                                <li>
                                  <Link
                                    className="last-view-all-menu text-primary"
                                    to={`/category/${subCat.id}`}
                                    onClick={() => setShow(false)}
                                  >
                                    مشاهده همه
                                  </Link>
                                </li>
                                {subCat.children &&
                                  subCat.children.map((item) => (
                                    <li key={item.id}>
                                      <Link to={`/category/${item.id}`} onClick={() => setShow(false)}>
                                        {item.title}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              )}

              <ul className="other-links">
                <li>
                  <a href="blog/article.html"> مجله صنعتی {configs.BRAND_NAME}</a>
                </li>
                <li className="mt10">
                  <a href="supplierslanding.html">فروش در {configs.BRAND_NAME}</a>
                </li>

                <li className="mt10">
                  <Link to={'/contact-us'}>تماس با ما</Link>
                </li>
              </ul>

            </div>
          </div>

          <div className="links">
            <Link to="/" onClick={() => setShow(false)}>ویدیوها</Link>
            <Link to="/" onClick={() => setShow(false)}>آماده ارسال</Link>
            <Link to="/faq/customer" onClick={() => setShow(false)}>سوالی دارید</Link>
            <Link to="/blog">مجله {configs.BRAND_NAME}</Link>
            {configs.SELLER_URL && (
              <div className="provider">
                <a
                  href={configs.SELLER_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={configs.GET_SELLER_BLOCK_BUTTON}
                >
                  {configs.GET_SELLER_BLOCK_BUTTON}
                </a>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

HeaderOffconvas.propTypes = {
  configs: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.mainReducer.categories,
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(HeaderOffconvas);
