import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

//
import SkeletopBox from "../../components/globals/SkeletopBox";

//
import AppleStore from "../../assets/img/apple-store.svg";
import GooglePlay from "../../assets/img/google-play.png";
import AppImage from "../../assets/img/app-banner.png";

//
import { getVersionInfo } from "../../redux/features/main/actions";

function Download() {
  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState({});

  /**
   * Get Version Info
   */
  const get_version_info = async () => {
    // start laoding
    setLoading(true);

    // request
    await getVersionInfo().then((res) => {
      if (res) {
        setInfo(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    get_version_info();
  }, []);

  return (
    <Container>
      <div className="cta-main-wrapper">
        <div className="cta-wrapper">
          <div className="image-wrapper">
            <img className="image cta-image" src={AppImage} alt="" />
          </div>

          <div className="cta-content">
            <h2 className="mb-4"> دریافت اپلیکیشن </h2>
            <p className="mb-2">
              {loading ? (
                <SkeletopBox width={150} />
              ) : (
                <>ورژن {info?.latest?.version}</>
              )}
            </p>
            <p className="mb-4 white-pre">
              {loading ? (
                <SkeletopBox width={150} />
              ) : (
                <>{info?.latest?.description}</>
              )}
            </p>
            <div className="mb-4 actions">
              {loading ? (
                <SkeletopBox height={61} borderRadius={4.8} width={221} />
              ) : (
                <a
                  href={info?.latest?.download}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-white btn-lg text-primary px-5 py-3"
                >
                  دریافت مستقیم
                </a>
              )}
            </div>
            <div className="apps-download">
              <img src={GooglePlay} alt="google-play" />
              <img src={AppleStore} alt="apple-store" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Download;
