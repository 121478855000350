import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

//
import { Container } from "react-bootstrap";

//
import SideBar from "./components/profile/SideBar";
import MobileHeader from "./components/profile/MobileHeader";
import MainLoading from "../components/globals/MainLoading";

//
import { getConfigs, getCurrencies } from "../redux/features/main/actions";
import { setWindowSize } from "../redux/features/theme/slice";
import { getCurrentUser } from "../redux/features/user/actions";
import { getNotificationsUnreadCount } from "../redux/features/notification/actions";

function Profile({
  getConfigs,
  windowSize,
  setWindowSize,
  configs,
  getCurrentUser,
  currencies,
  getCurrencies,
  getNotificationsUnreadCount
}) {
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  const [sidebarStatus, setSidebarStatus] = useState(true);

  useEffect(() => {
    setWindowSize(window.innerWidth);
    const handleResizeWindow = () => setWindowSize(window.innerWidth);

    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
  }, []);

  useEffect(() => {
    setSidebarStatus(windowSize > 992 ? true : false);
  }, [windowSize]);

  useEffect(async () => {
    setLoading(true);
    await getCurrentUser();
    await getNotificationsUnreadCount()
    if (!currencies.length) {
      await getCurrencies();
    }
    if (!Object.keys(configs).length) {
      await getConfigs();
    }
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return token ? (
    <div id="profile">
      {loading && <MainLoading />}
      {sidebarStatus && (
        <div
          className="click-box d-block d-lg-none"
          onClick={() => setSidebarStatus(false)}
        ></div>
      )}

      <SideBar
        sidebarStatus={sidebarStatus}
        setSidebarStatus={setSidebarStatus}
      />
      <div className={`profile-main ${sidebarStatus ? "active" : ""}`}>
        <Container>
          <MobileHeader setSidebarStatus={setSidebarStatus} />
          <div className="page-content">
            <Outlet />
          </div>
        </Container>
      </div>
    </div>
  ) : (
    <Navigate to="/" replace />
  );
}

Profile.propTypes = {
  getConfigs: PropTypes.func.isRequired,
  setWindowSize: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getNotificationsUnreadCount: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getConfigs,
  setWindowSize,
  getCurrentUser,
  getCurrencies,
  getNotificationsUnreadCount
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
  currencies: state.mainReducer.currencies,
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
