import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import { Button, Card } from "react-bootstrap";

function MobileCommentsQuestions({ item, showComment }) {
  return (
    <Card className="panel-product dark-border">
      <Card.Body>
        <Link to={`/product/${item.product.id}`} className="img-holder">
          <img src={item.product.main_image} alt="product" />
        </Link>

        <div className="product-title">
          <small className="product-code">{item.product.code}</small>
          <Link to={`/product/${item.product.id}`}>
            <small className="product-name">{item.product.title}</small>
          </Link>
        </div>

        <Button
          variant="primary"
          className="w-100 mt-2 text-white"
          onClick={() => showComment(item)}
        >
          مشاده
        </Button>
      </Card.Body>
    </Card>
  );
}

MobileCommentsQuestions.propTypes = {
  item: PropTypes.object.isRequired,
  showComment: PropTypes.func.isRequired,
};

export default MobileCommentsQuestions;
