import React from "react";
import PropTypes from "prop-types";

//
import useCurrency from "../../hooks/useCurrency";

//
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//
import moment from "jalali-moment";
import { Button, Card, Dropdown } from "react-bootstrap";

//
import NumberFormat from "react-number-format";
import { addToCart } from "../../redux/features/cart/actions";

function Product({
  item,
  quantity,
  price,
  date,
  product_instance_id = 0,
  moreInfo = false,
  favorite = false,
  reorder = false,
  removeFavorite,
}) {
  const [currencyTitle] = useCurrency();

  /**
   * Add To Cart
   */
  const add_to_cart = async (product_instance_id, quantity) => {
    // variable
    let content = {
      product_instance_id,
      quantity,
    };

    // request
    await addToCart(content).then((res) => {
      if (res) {
        toast.success("محصول به سبد خرید شما افزوده شد", {
          theme: "colored",
          icon: <i className="fas fa-bell toast_icons"> </i>,
          closeButton: (
            <i className="fas fa-times-circle toast_close_button toast_icons"></i>
          ),
        });
      }
    });
  };

  return (
    <Card className="panel-product dark-border">
      <Card.Body>
        {moreInfo && (
          <Dropdown className="other-link" align="end">
            <Dropdown.Toggle variant="light">
              <i className="fas fa-ellipsis-v"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>مشاهده بیجک</Dropdown.Item>
              <Dropdown.Item>ثبت نظر</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        <div className="img-holder">
          <img src={item.main_image} alt={item.title} />
        </div>
        <div className="product-title">
          <small className="product-code">{item.code}</small>
          <small className="product-name">{item.title}</small>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          {date && (
            <small className="text-muted">
              {moment(date, "YYYY/MM/DD HH:mm").locale("fa").format("YYYY/MM/DD HH:mm")}
            </small>
          )}
          {quantity && <p>{quantity} عدد</p>}
        </div>
        <p className="price">
          <span className="currency-toman">{currencyTitle()}</span>
          <NumberFormat
            value={+price}
            displayType="text"
            thousandSeparator={true}
            className="ms-1 text-bold text-black"
          />
        </p>

        {favorite && (
          <Button
            variant="primary"
            className="w-100 mt-3"
            onClick={() => removeFavorite(item.id)}
          >
            حذف
          </Button>
        )}

        {reorder && (
          <Button
            variant="primary"
            className="w-100 mt-3"
            onClick={() => add_to_cart(product_instance_id, quantity)}
          >
            سفارش مجدد
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
  moreInfo: PropTypes.bool,
  favorite: PropTypes.bool,
};

export default Product;
