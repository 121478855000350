import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo.js";

//
import { Container, Row, Col, Card, Modal, Form } from "react-bootstrap";

//
import Breadcrumb from "../../components/globals/Breadcrumb.jsx";
import SkeletonBox from "../../components/globals/SkeletopBox.jsx";

//
import Filter from "../../components/category/Filter.jsx";
// import FindBox from "../../components/category/FindBox.jsx";
import Products from "../../components/category/Products.jsx";
// import PaginationBox from "../../components/category/PaginationBox.jsx";
import DescriptionBox from "../../components/category/DescriptionBox.jsx";
import RelatedArticles from "../../components/category/RelatedArticles.jsx";
import RelatedQuestions from "../../components/category/RelatedQuestions.jsx";
// import BestSellingProducts from "../../components/category/BestSellingProducts.jsx";

import MetaDecorator from "../../components/globals/MetaDecorator.jsx";

//
import { categorySort } from "../../constances.js";
import {
  getCategoryInfo,
  searchProduct,
} from "../../redux/features/main/actions.js";

function Search(props) {
  let { slug } = useParams();
  const [searchParams] = useSearchParams();
  const [seo, set_seo] = useSeo();

  const [query, setQuery] = useState({});

  const [breadcrump, setBreadcrump] = useState([]);

  const [changedBefore, setChangedBefore] = useState(false);

  // category
  const [categoryInfo, setCategoryInfo] = useState({});
  const [categoryInfoLoading, setCategoryInfoLoading] = useState(false);

  // product
  const [product, setProduct] = useState({});
  const [productLoading, setProductLoading] = useState(false);

  // search params
  const [attributes, setAttributes] = useState({});

  // sort
  const [sortType, setSortType] = useState({
    name: "گران ترین",
    value: "price",
    type: "desc",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [sortModal, setSortModal] = useState(false);

  /**
   * Set Breacrump
   */
  const create_breadcrump = (category) => {
    let parent = category.parent;
    let array = [
      {
        id: parent.id,
        type: "category-info",
        slug: parent.slug,
        title: parent.title,
      },
      {
        id: category.id,
        type: "search",
        slug: category.slug,
        title: category.title,
      },
    ];

    setBreadcrump(array);
  };

  /**
   * Get Query From URL
   */
  const getQueryParams = () => {
    let queries = {};
    for (let entry of searchParams.entries()) {
      let title = entry[0];
      let value = entry[1];
      if (title && value) {
        queries[title] = value;
      }
    }

    get_category_info(queries);

    search_for_product(1, queries);

    setQuery(queries);
  };

  /**
   * Set Attributes
   */
  const set_attributes = (attributesInfo, selectedAttributes) => {
    let attrs = {};
    for (let i = 0; i < attributesInfo.length; i++) {
      const element = attributesInfo[i];
      attrs[element.id] = {
        id: element.id,
        title: element.title,
        value_format: element.value_format,
        available_values: {},
      };

      for (let a = 0; a < element.attribute_values.length; a++) {
        const values = element.attribute_values[a];
        attrs[element.id].available_values[values.id] = {
          id: values.id,
          name: values.value,
          value: false,
        };
      }
    }

    setAttributes(attrs);

    update_attributes(selectedAttributes, attrs);
  };

  const update_attributes = (selectedAttributes, storedAttrs = attributes) => {
    let storedAttributes = { ...storedAttrs };

    for (const [mainKey, mainValue] of Object.entries(storedAttributes)) {
      for (const [key, value] of Object.entries(mainValue.available_values)) {
        storedAttributes[mainKey].available_values[key].value = false;
      }
    }

    if (selectedAttributes) {
      for (let i = 0; i < JSON.parse(selectedAttributes).length; i++) {
        const selected = JSON.parse(selectedAttributes)[i];
        for (let v = 0; v < selected.values.length; v++) {
          const element = selected.values[v];
          storedAttributes[selected.id].available_values[element].value = true;
        }
      }
    }
  };

  /**
   * Get Category Info
   */
  const get_category_info = async (queries) => {
    let category_id = queries.category_id;
    let attributes = queries.attributes;

    if (category_id && query.category_id != category_id) {
      // setChangedBefore(false);
      // start loading
      setCategoryInfoLoading(true);

      // request
      await getCategoryInfo(category_id).then((res) => {
        if (res) {
          setCategoryInfo(res);
          set_seo(res.seo, res.title);
          set_attributes(res.nested_options, attributes);
          // setChangedBefore(true);

          create_breadcrump(res);
        }
      });

      // stop loading
      setCategoryInfoLoading(false);
    } else {
      setCategoryInfo({});
      setAttributes({});
    }
  };

  /**
   * Search For Products
   */
  const search_for_product = async (page, searchInfo = query) => {
    // start loading
    setProductLoading(true);

    // set variables
    let params = new URLSearchParams();
    params.set("page", page);

    for (const [key, value] of Object.entries(searchInfo)) {
      params.set(key, value);
    }

    // request
    await searchProduct(params).then((res) => {
      if (res) {
        let data = {
          meta: {
            current_page: res.current_page || 1,
            max_price: res?.meta?.priceRange?.maxPrice || 0,
            min_price: res?.meta?.priceRange?.minPrice || 0,
            max_page: res.last_page || 0,
            total_items: res.total || 0,
          },
          result: res.data,
        };

        if (page === 1) {
          setProduct(data);
        } else {
          let productsList = { ...product };
          productsList.meta = data.meta;
          productsList.result = [...product.result, ...data.result];
          setProduct(productsList);
        }
      }
    });

    // stop loading
    setProductLoading(false);
  };

  useEffect(() => {
    getQueryParams();

    // eslint-disable-next-line
  }, [searchParams]);

  // useEffect(() => {
  //   search_for_product(1);

  //   // eslint-disable-next-line
  // }, [
  //   changedBefore,
  //   categoryInfo,
  //   selectedAttributes,
  //   selectedMinMax,
  //   availables,
  //   sortType,
  // ]);

  return (
    <div id="category">
      <MetaDecorator seo={seo} />

      {showFilter && (
        <div className="click-box light" onClick={() => setShowFilter(false)} />
      )}

      <Container>
        {/* Breadcrump & Title */}
        <Row className="header-box">
          {/* Breadcrump */}
          <Col lg={3}>
            {categoryInfoLoading ? (
              <div className="mb-4">
                <SkeletonBox height={20} />
              </div>
            ) : (
              <Breadcrumb array={breadcrump} />
            )}
          </Col>

          {/* Title */}
          <Col lg={9}>
            {categoryInfoLoading ? (
              <SkeletonBox height={20} width={200} />
            ) : (
              <div className="category-name">
                <h6 className="text-bolder">
                  {categoryInfo.title || query.search_query}
                </h6>
                <small>({product?.meta?.total_items || 0} محصول)</small>
              </div>
            )}
          </Col>
        </Row>

        {/* Filter & content */}
        <Row>
          <Col xl={3} className="d-none d-xl-block">
            <Filter
              queries={query}
              info={categoryInfo}
              loading={categoryInfoLoading}
              min_price={product.meta ? +product.meta.min_price : 0}
              max_price={product.meta ? +product.meta.max_price : 0}
              attributes={attributes}
            />

            {/* <BestSellingProducts /> */}
          </Col>

          <Col xl={9}>
            <Row className="gutter-b">
              {/* Dropdown filter */}
              <Col lg={3} className="d-none d-lg-block d-xl-none">
                <div className="filter-dropdown">
                  <div
                    className="h-100 d-flex align-items-center justify-content-between"
                    onClick={() => setShowFilter(true)}
                  >
                    <h6>فیلتر</h6>

                    <i className="fas fa-chevron-down"></i>
                  </div>

                  {showFilter && (
                    <Filter
                      queries={query}
                      info={categoryInfo}
                      loading={categoryInfoLoading}
                      min_price={product.meta ? +product.meta.min_price : 0}
                      max_price={product.meta ? +product.meta.max_price : 0}
                      attributes={attributes}
                    />
                  )}
                </div>
              </Col>

              {/* Sort filter */}
              <Col lg={9} xl={12} className="d-none d-lg-block ">
                <Card className="sorting-list">
                  <Card.Body>
                    {categorySort.map((item, index) => (
                      <div
                        className={`item ${
                          JSON.stringify(item) === JSON.stringify(sortType)
                            ? "active"
                            : ""
                        }`}
                        key={index}
                      >
                        <h6
                          className={`text-regular pointer ${
                            JSON.stringify(item) === JSON.stringify(sortType)
                              ? "text-primary"
                              : ""
                          }`}
                          onClick={() => setSortType(item)}
                        >
                          {item.name}
                        </h6>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </Col>

              {/* Sort & Filter in mobile */}
              <Col xs={12} className="d-block d-lg-none">
                <Row className="sorting">
                  {/* Filter */}
                  <Col xs={6} className="item">
                    <div className="holder" onClick={() => setShowFilter(true)}>
                      <i className="ml-2 fas fa-filter"></i>
                      فیلتر و دسته بندی ها
                    </div>

                    <div
                      className={`mobile-filter ${showFilter ? "active" : ""}`}
                    >
                      <header>
                        <i
                          className="fas fa-times"
                          onClick={() => setShowFilter(false)}
                        ></i>
                        <span>فیلتر و دسته بندی‌ها</span>
                      </header>
                      <div className="filter-box">
                        <Row className="justify-content-center">
                          <Col sm={8}>
                            <Filter
                              queries={query}
                              info={categoryInfo}
                              loading={categoryInfoLoading}
                              min_price={
                                product.meta ? +product.meta.min_price : 0
                              }
                              max_price={
                                product.meta ? +product.meta.max_price : 0
                              }
                              attributes={attributes}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  {/* Sort */}
                  <Col xs={6} className="item">
                    <div className="holder" onClick={() => setSortModal(true)}>
                      <i className="ml-2 fas fa-filter"></i>
                      مرتب سازی
                    </div>

                    <Modal
                      centered
                      id="sort-modal"
                      show={sortModal}
                      onHide={() => setSortModal(false)}
                    >
                      <Modal.Header
                        closeButton
                        className="no-border"
                      ></Modal.Header>
                      <Modal.Body>
                        <div className="sort-list">
                          {categorySort.map((item, index) => (
                            <Form.Check
                              key={index}
                              type="checkbox"
                              id={item.name}
                              label={item.name}
                              checked={
                                JSON.stringify(item) ===
                                JSON.stringify(sortType)
                              }
                              onChange={() => setSortType(item)}
                            />
                          ))}
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Products
              products={product.result || []}
              currentPage={product?.meta?.current_page || 1}
              lastPage={product?.meta?.max_page || 1}
              getMoreProduct={(page) => search_for_product(page)}
            />

            {/* {product.meta && product.meta.max_page > 1 && (
              <PaginationBox
                currentPage={product.meta.current_page - 1}
                lastPage={product.meta.max_page}
                changePage={(page) => search_for_product(page.selected + 1)}
              />
            )} */}

            {/* <FindBox /> */}
          </Col>

          <Col xs={12}>
            {categoryInfo.linked_blog_posts &&
              !!categoryInfo.linked_blog_posts.length && (
                <RelatedArticles blogs={categoryInfo.linked_blog_posts} />
              )}

            <RelatedQuestions />

            <DescriptionBox info={categoryInfo} loading={categoryInfoLoading} />

            {/* <TitleSwiper text="آخرین بازدید های شما" /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Search.propTypes = {};

export default Search;
