import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

//
import moment from "jalali-moment";

//
import SkeletopBox from "../../globals/SkeletopBox";
import Pagination from "../../globals/Pagination";
import NewCommentQuestionModal from "./NewCommentQuestionModal";

//
import { getProductComments } from "../../../redux/features/main/actions";

function BuyersComments({ setCommentsCount }) {
  let { slug } = useParams();

  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(false);

  /**
   * Get Comments
   */
  const get_product_comments = async (page) => {
    // request
    await getProductComments({ slug, page }).then((res) => {
      if (res) {
        setComments(res);
        setCommentsCount(res.total);
      }
    });
  };

  useEffect(async () => {
    // start loading
    setLoading(true);

    await get_product_comments(1);

    // stop loading
    setLoading(false);
  }, [slug]);

  return (
    <div className="buyers-comment">
      <p className="title">نظر خریداران</p>

      <NewCommentQuestionModal id={slug} type="comments" />

      {loading ? (
        <>
          <p className="mb-1">
            <SkeletopBox height={25} width={150} />
          </p>
          <p>
            <SkeletopBox height={25} width={250} />
          </p>
        </>
      ) : (
        <>
          {comments.data &&
            comments.data.map((item) => (
              <div className="comment" key={item.id}>
                <small className="info text-muted">
                  <span>
                    {item?.customer?.first_name} {item?.customer?.last_name}
                  </span>

                  <span>{moment(item.created_at).locale("fa").fromNow()}</span>
                </small>

                {item?.body?.title && <h6>{item?.body?.title}</h6>}
                <p>{item?.body?.body}</p>

                {(!!item?.body?.negative?.length ||
                  !!item?.body?.posetive?.length) && (
                  <div className="opinion">
                    {item?.body?.posetive.map((item, index) => (
                      <small className="d-flex" key={index}>
                        <i className="fas fa-plus text-success ms-1"></i>
                        {item}
                      </small>
                    ))}

                    {item?.body?.negative.map((item, index) => (
                      <small className="d-flex" key={index}>
                        <i className="fas fa-minus text-danger ms-1"></i>
                        {item}
                      </small>
                    ))}
                  </div>
                )}
              </div>
            ))}

          {comments.last_page > 1 && (
            <Pagination
              currentPage={+comments.current_page - 1}
              lastPage={comments.last_page}
              changePage={(page) => get_product_comments(page.selected + 1)}
            />
          )}
        </>
      )}
    </div>
  );
}

BuyersComments.propTypes = {
  setCommentsCount: PropTypes.func.isRequired,
};

export default BuyersComments;
