import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";
import useStorage from "../../hooks/useStorage";

//
import moment from "jalali-moment";
import { Card, Form, Button, Spinner } from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

// React Dropzone
import { useDropzone } from "react-dropzone";

//
import {
  uploadFile,
  getTicket,
  replyTicket,
  closeTicket,
} from "../../redux/features/user/actions";

//
import AddImageSvg from "../../assets/img/addphoto.svg";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  body: yup.string().required(),
});

function TicketInfo() {
  let { slug } = useParams();
  const [seo, set_seo] = useSeo();
  const [storageImage] = useStorage();

  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(false);
  const [ticketLoading, setTicketLoading] = useState(false);

  const convertStatusToHex = (status) => {
    let statuses = {
      answered: "#1BC5BD",
      pending: "#FFA800",
      processing: "#FFA800",
      close: "#F64E60",
    };
    return statuses[status];
  };

  const convertPeriority = (id) => {
    let ids = {
      1: "کم",
      2: "متوسط",
      3: "زیاد",
    };
    return ids[id];
  };

  const priorityColor = (id) => {
    let ids = {
      1: "success",
      2: "warning",
      3: "danger",
    };
    return ids[id];
  };

  const convertStatus = (status) => {
    let statuses = {
      answered: "پاسخ داده شده",
      pending: "در انتظار",
      processing: "در حال بررسی",
      close: "بسته شده",
    };
    return statuses[status];
  };

  const statusColor = (status) => {
    let statuses = {
      answered: "success",
      pending: "warning",
      processing: "warning",
      close: "danger",
    };
    return statuses[status];
  };

  /**
   * Get Ticket
   */
  const get_ticket = async () => {
    // start loading
    setTicketLoading(true);

    await getTicket(slug).then((res) => {
      if (res) {
        setTicket(res);
      }
    });

    // stop loading
    setTicketLoading(false);
  };

  /**
   * Reply Ticket
   */
  const reply_ticket = async (e, { resetForm }) => {
    // start loading
    setLoading(true);

    // variable
    let content = { ...e };
    let fd = new FormData();
    let params = new URLSearchParams();
    params.set("server_id", 1);
    params.set("path", "/ticket");

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        fd.append(`files[${i}]`, element);
      }

      // Upload File then Create Ticket
      await uploadFile({ params, fd }).then(async (res) => {
        if (res) {
          let uploadedFiles = [];

          for (let i = 0; i < res.length; i++) {
            const element = res[i];
            uploadedFiles.push({
              size: element.size,
              name: element.file,
              type: element.mime_type,
              url: storageImage(`${element.path}/${element.file}`),
            });
          }

          content.files = uploadedFiles;

          await replyTicket({ slug, fd }).then((res) => {
            if (res) {
              // resetForm({
              //   values: "",
              // });
              setTicket(res);
              setFiles([]);
            }
          });
        }
      });
    } else {
      // Reply Ticket
      await replyTicket({ slug, fd }).then((res) => {
        if (res) {
          // resetForm({
          //   values: "",
          // });
          setTicket(res);
          setFiles([]);
        }
      });
    }

    // stop loading
    setLoading(false);
  };

  /**
   * Close Ticket
   */
  const close_ticket = async () => {
    await closeTicket(slug).then((res) => {
      if (res) {
        let info = { ...ticket };

        info.status = "close";
        setTicket(info);
      }
    });
  };

  /**
   * Dropzone
   */
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  /**
   * Remove Image
   */
  const removeImage = (image) => {
    let filesArray = [...files];

    filesArray = filesArray.filter((item) => item != image);

    setFiles(filesArray);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    set_seo({}, `تیکت ${slug}`);
    get_ticket();

    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <MetaDecorator seo={seo} />

      <Card className="no-radius dark-border" id="reply-ticket">
        <Card.Body className="panel-item-box">
          {/* Header */}
          <div className="d-flex align-items-center mb-4">
            {/* Info */}
            <div className="d-flex align-items-center w-100">
              {/* Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16 14C16.4 13.6 16.4 12.9 16 12.5C15.6 12.1 15.4 12.6 15 13L11 16L9 15C8.6 14.6 8.4 14.1 8 14.5C7.6 14.9 8.1 15.6 8.5 16L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z"
                  fill={convertStatusToHex(ticket?.status)}
                ></path>
                <path
                  d="M10.4343 15.4343L9.25 14.25C8.83579 13.8358 8.16421 13.8358 7.75 14.25C7.33579 14.6642 7.33579 15.3358 7.75 15.75L10.2929 18.2929C10.6834 18.6834 11.3166 18.6834 11.7071 18.2929L16.25 13.75C16.6642 13.3358 16.6642 12.6642 16.25 12.25C15.8358 11.8358 15.1642 11.8358 14.75 12.25L11.5657 15.4343C11.2533 15.7467 10.7467 15.7467 10.4343 15.4343Z"
                  fill={convertStatusToHex(ticket?.status)}
                ></path>
                <path
                  d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                  fill={convertStatusToHex(ticket?.status)}
                ></path>
              </svg>

              {/* Text */}
              <div className="d-flex flex-column me-1">
                <h5 className="text-gray-800 fw-semibold mb-2">
                  {ticket.subject}
                </h5>

                <div>
                  <span className="fw-semibold text-muted">
                    وضعیت:{" "}
                    <span
                      className={`fw-bold text-${statusColor(ticket.status)}`}
                    >
                      {convertStatus(ticket.status)}{" "}
                    </span>
                  </span>

                  <span className="fw-semibold text-muted mx-3">
                    اهمیت:{" "}
                    <span
                      className={`fw-bold text-${priorityColor(
                        ticket.priority
                      )}`}
                    >
                      {convertPeriority(ticket.priority)}{" "}
                    </span>
                  </span>

                  {ticket.created_at && (
                    <span className="fw-semibold text-muted">
                      تاریخ:{" "}
                      <span className="font-weight-bold">
                        {moment(ticket.created_at, "YYYY/MM/DD HH:mm")
                          .locale("fa")
                          .format("YYYY/MM/DD HH:mm")}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Close */}
            {ticket.status != "close" && (
              <span
                className="max-content text-danger cursor-pointer"
                onClick={() => close_ticket()}
              >
                بستن تیکت
              </span>
            )}
          </div>

          {/* Replies */}
          {ticket.replies &&
            ticket.replies.map((reply, index) => (
              <Card className="w-100 mb-3" key={index}>
                <Card.Body>
                  {/* Header */}
                  <div
                    className={`w-100 d-flex align-items-center justify-content-between mb-3 ${
                      reply.admin ? "flex-row-reverse" : ""
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center  ${
                        reply.admin ? "flex-row-reverse" : ""
                      }`}
                    >
                      {/* Avatar */}
                      <div className="symbol">
                        <div
                          className="symbol-label fw-bold bg-success text-white"
                          style={{
                            backgroundImage: `url(${
                              reply?.admin?.avatar || reply?.user?.avatar
                            })`,
                          }}
                        >
                          {!reply?.admin?.avatar && !reply?.user?.avatar && (
                            <h3>
                              {reply?.admin
                                ? reply?.admin?.name?.charAt(0)
                                : reply?.user?.name?.charAt(0)}
                            </h3>
                          )}
                        </div>
                      </div>

                      {/* Name */}
                      <div className="d-flex flex-column fw-semibold mx-2">
                        <div
                          className={`d-flex align-items-center ${
                            reply.admin ? "flex-row-reverse" : ""
                          }`}
                        >
                          <h5 className="text-gray-800 fw-bold mb-2">
                            {reply?.admin?.name || reply?.user?.name}
                          </h5>

                          <span className="m-0"></span>
                        </div>

                        {/* Date */}
                        <span
                          className={`text-muted fw-semibold  ${
                            reply.admin ? "text-start" : ""
                          }`}
                        >
                          {moment(reply.created_at, "YYYY/MM/DD HH:mm")
                            .locale("fa")
                            .format("YYYY/MM/DD HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Body */}
                  <p className={reply.admin ? "text-start" : ""}>
                    {reply.body}
                  </p>

                  {/* Files */}
                  {reply.files && (
                    <div
                      className={`d-flex align-items-center mt-4  ${
                        reply.admin ? "flex-row-reverse" : ""
                      }`}
                      style={{ gap: "1rem" }}
                    >
                      {reply.files.map((file, index) => (
                        <a
                          key={index}
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="symbol"
                        >
                          <div
                            className="symbol-label font-weight-bold"
                            style={{
                              backgroundImage: `url(${file.url})`,
                            }}
                          ></div>
                        </a>
                      ))}
                    </div>
                  )}
                </Card.Body>
              </Card>
            ))}

          {/* Form */}
          <Formik
            validationSchema={schema}
            onSubmit={reply_ticket}
            initialValues={{
              body: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik01"
                >
                  <Form.Label className="bold">
                    متن پیام
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    type="text"
                    name="body"
                    onChange={handleChange}
                    autoComplete="off"
                    className="form-control form-control-solid big right-border"
                    isInvalid={touched.body && !!errors.body}
                  />
                </Form.Group>

                <section className="dropzone">
                  <div>
                    {files.length ? (
                      <div className="thump-box">
                        {files.map((file, index) => (
                          <div className="thump" key={file.name}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="item">
                                      <img src={file.preview} alt="" />
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <span className="size">
                                      {formatBytes(file.size)}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="icons">
                                      <i
                                        className="fas fa-times-circle text-danger"
                                        onClick={() => removeImage(file)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div {...getRootProps({ className: "input-text" })}>
                        <input {...getInputProps()} />
                        <img src={AddImageSvg} alt="add-image" />
                        <p className="title">فایل</p>
                        <Button variant="dark">اضافه کردن</Button>
                      </div>
                    )}
                  </div>
                </section>

                <Button
                  type="submit"
                  variant="primary"
                  className="text-white submit-button d-block me-auto"
                >
                  ذخیره
                  {loading && (
                    <div className="spinner-holder">
                      <Spinner animation="border" variant="white" size="sm" />
                    </div>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
}

export default TicketInfo;
