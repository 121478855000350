import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { Card } from "react-bootstrap";
import NumberFormat from "react-number-format";

//
import { addToCart } from "../../redux/features/cart/actions";

function SidebarProduct({ product, selectedSeller }) {
  const [currencyTitle] = useCurrency();

  let navigate = useNavigate();

  /**
   * Add To Cart
   */
  const add_to_cart = async () => {
    // variable
    let content = {
      product_instance_id: selectedSeller.instance.id,
      quantity: 1,
    };

    // request
    await addToCart(content).then((res) => {
      if (res) {
        navigate("/cart");
      }
    });
  };

  return (
    <Card className="sidebar-product">
      <Card.Body>
        <img src={product.main_image} alt="image" />
        <p className="title">{product.title}</p>
        {selectedSeller.info.id ? (
          <div className="d-flex align-items-center justify-content-end gap-1">
            <small className="me-1">{currencyTitle()}</small>
            <h5 className="text-black">
              <NumberFormat
                value={+selectedSeller.instance.original_price}
                displayType="text"
                thousandSeparator={true}
              />
            </h5>
          </div>
        ) : (
          <h5 className="d-flex align-items-center justify-content-end">
            ناموجود
          </h5>
        )}

        <div
          className="buy-btn"
          onClick={() => selectedSeller.info.id && add_to_cart()}
        >
          {selectedSeller.info.id ? "خرید آنلاین" : "ناموجود"}
        </div>
      </Card.Body>
    </Card>
  );
}

SidebarProduct.propTypes = {
  product: PropTypes.object.isRequired,
  selectedSeller: PropTypes.object.isRequired,
};

export default SidebarProduct;
