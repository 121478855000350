import React from "react";

//
import SkeletopBox from "../globals/SkeletopBox";

//
import { Col, Container, Row } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function DashboardLoading() {
  return (
    <>
      <Container>
        <div className="main-slider">
          <SkeletopBox height={400} borderRadius={12} />
        </div>
      </Container>

      <Container>
        <div className="title-swiper">
          <div className="before-title">
            <p>
              <SkeletopBox width={100} />
            </p>
          </div>

          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            breakpoints={{
              400: {
                slidesPerView: 2,
              },
              576: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1300: {
                slidesPerView: 6,
              },
            }}
          >
            {[1, 2, 3, 4, 5].map((item) => (
              <SwiperSlide key={item}>
                <SkeletopBox height={284} borderRadius={4} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>

      <div className="ad-banners">
        <Container>
          <Row>
            <Col md={6} className="gutter-b">
              <SkeletopBox height={200} />
            </Col>

            <Col md={6} className="gutter-b">
              <SkeletopBox height={200} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default DashboardLoading;
