import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Get Categories
 */
export const getBlogCategories = createAsyncThunk(
    "getBlogCategories",
    async() => {
        try {
            const { data } = await axios.get("shop/blog/categories");

            //
            return data.result;
        } catch (error) {
            return false;
        }
    }
);

/**
 * Get Category Blogs
 */
export const getCategoryBlogs = async() => {
    try {
        const { data } = await axios.get('shop/blog/categories/blocks');

        //
        return data.result;
    } catch (error) {
        return false
    }
}

/**
 * Get Featured Posts
 */
export const getFeaturedPosts = async() => {
    try {
        const { data } = await axios.get("shop/blog/posts/featured");

        //
        return data.result;
    } catch (error) {
        return false;
    }
};

/**
 * Search Blog
 * @param {search params} payload
 */
export const searchBlog = async(payload) => {
    try {
        const { data } = await axios.get("shop/blog/posts", {
            params: payload,
        });

        //
        return data.result;
    } catch (error) {
        return false;
    }
};

/**
 * Get Post
 * @param {post slug} payload
 */
export const getBlogPost = async(payload) => {
    try {
        const { data } = await axios.get(`shop/blog/posts/${payload}`);

        //
        return data.result;
    } catch (error) {
        return false;
    }
};