import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import { Card, Modal, Row, Col } from "react-bootstrap";

//
import { getUserQuestions } from "../../redux/features/user/actions";

//
import NothingToShow from "../../components/globals/NothingToShow";
import BoxLoading from "../../components/globals/BoxLoading";
import BeforeText from "../../components/globals/BeforeText";
import Pagination from "../../components/globals/Pagination";
import MobileCommentsQuestions from "../../components/panel/MobileCommentsQuestions";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Questions({ windowSize, configs }) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState({});

  //
  const [show, setShow] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});

  const showQuestion = (item) => {
    setSelectedQuestion(item);
    setShow(true);
  };

  /**
   * Get Questions
   */
  const get_questions = async (page) => {
    // start loading
    setLoading(true);

    // request
    await getUserQuestions(page).then((res) => {
      if (res) {
        setQuestions(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    set_seo({}, "پرسش و پاسش");
    get_questions(1);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div className="questions">
        <div className="page-title">پرسش و پاسخ</div>

        {questions.data && questions.data.length ? (
          <>
            {windowSize > 768 ? (
              <Card className="dark-border">
                <Card.Body className="panel-item-box">
                  <table>
                    <thead>
                      <tr>
                        <th width="15%"></th>
                        <th width="20%"></th>
                        <th width="45%"></th>
                        <th width="20%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {questions.data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/product/${item.product.id}`}>
                              <img
                                className="img-fluid"
                                src={item.product.main_image}
                                alt={item.product.title}
                              />
                            </Link>
                          </td>
                          <td>
                            <p className="code">{item.product.code}</p>
                            <Link to={`/product/${item.product.id}`}>
                              {item.product.title}
                            </Link>
                          </td>

                          <td></td>

                          <td className="text-start">
                            <p
                              className="text-primary pointer"
                              onClick={() => showQuestion(item)}
                            >
                              مشاهده
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            ) : (
              <Row>
                {questions.data.map((item) => (
                  <Col sm={4} key={item.id} className="gutter-b">
                    <MobileCommentsQuestions
                      item={item}
                      showQuestion={showQuestion}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </>
        ) : (
          <Card className="dark-border">
            <Card.Body>
              <NothingToShow text="مورد برای نمایش وجود ندارد" />

              {loading && <BoxLoading />}
            </Card.Body>
          </Card>
        )}

        {questions.last_page && questions.last_page > 1 && (
          <Pagination
            currentPage={questions.current_page - 1}
            lastPage={questions.last_page}
            changePage={(page) => get_questions(page.selected + 1)}
          />
        )}

        <Modal id="question-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton className="no-border absolute-close pb-0">
            <BeforeText text="پرسش و پاسخ" small />
          </Modal.Header>
          <Modal.Body>
            <div className="question">
              <i className="far fa-question-circle"></i>

              {selectedQuestion.body}
            </div>

            {selectedQuestion.answer && (
              <div className="answer">
                <small className="name text-muted d-flex align-items-center">
                  <p className="ms-2 text-primary">پاسخ</p>
                  {configs.BRAND_NAME}
                </small>
                <p>{selectedQuestion.answer.body}</p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

Questions.propTypes = {
  windowSize: PropTypes.number.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(Questions);
