import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useCurrency from "../../hooks/useCurrency";

//
import { offlinePayment, payOrder } from "../../redux/features/cart/actions";

//
import { Card, Button, Row, Col, Spinner, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema = yup.object().shape({
  payer_name: yup.string().required("لطفا نام پرداخت کننده را وارد کنید"),
  bank_name: yup.string().required("لطفا نام بانک را وارد کنید"),
  account_number: yup.string().required("لطفا شماره حساب را وارد کنید"),
  ref_code: yup.string().required("لطفا شماره پیگیری را وارد کنید"),
});

function PaymentGateWays({
  user,
  gateWays,
  order_id,
  price,
  set_selected_gateways = false,
}) {
  const [currencyTitle] = useCurrency();

  const [loading, setLoading] = useState(false);
  const [offlineLoading, setOfflineLoading] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState({});

  const payablePrice = () => {
    if (selectedGateway.type === "offline") return price;

    return price - +user.credit;
  };

  /**
   * Offline Payment
   */
  const offline_payment = async (e) => {
    // start loading
    setOfflineLoading(true);

    // variable
    let module_name = selectedGateway.module_name;
    let content = e;

    // request
    await offlinePayment({ order_id, module_name, content });

    // stop loading
    setOfflineLoading(false);
  };

  /**
   * Send Pay Request
   */
  const pay_request = async (order_id) => {
    // start loading
    setLoading(true);

    // variable
    let module_name = selectedGateway.module_name;

    // request
    payOrder({ order_id, module_name }).then((res) => {
      if (res) {
        setLoading(false);
        window.location = res.paymentUrl
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (gateWays.length) setSelectedGateway(gateWays[0]);
  }, [gateWays]);

  useEffect(() => {
    if (selectedGateway.id && set_selected_gateways) {
      set_selected_gateways(selectedGateway);
    }
  }, [selectedGateway]);

  return (
    <>
      {price && (
        <div className="prices">
          <div className="item total">
            <span className="title">مبلغ قابل پرداخت:</span>
            <NumberFormat
              value={payablePrice()}
              displayType="text"
              thousandSeparator={true}
              className="text-success price text-bold"
            />

            <small>{currencyTitle()}</small>
          </div>
        </div>
      )}
      <div className="d-flex gateways">
        {gateWays.map((item) => (
          <div
            onClick={() => setSelectedGateway(item)}
            className={`item text-center ${
              item.id === selectedGateway.id ? "active" : ""
            }`}
          >
            <img src={item.logo} alt={item.title} />
            <p className="mt-2">{item.title}</p>
          </div>
        ))}
      </div>
      {selectedGateway.type === "offline" ? (
        <Row className="justify-content-center w-100 mt-4">
          <Col md={4}>
            <Formik
              validationSchema={schema}
              onSubmit={offline_payment}
              initialValues={{
                payer_name: "",
                bank_name: "",
                account_number: "",
                ref_code: "",
                description: "",
              }}
            >
              {({ handleSubmit, handleChange, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/* Payer Name */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik00"
                  >
                    <Form.Label className="bold text-muted">
                      پرداخت کننده
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="payer_name"
                      className="form-control-solid red-focus"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.payer_name && !!errors.payer_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.payer_name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Bank Name */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik01"
                  >
                    <Form.Label className="bold text-muted">
                      نام بانک
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="bank_name"
                      className="form-control-solid red-focus"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.bank_name && !!errors.bank_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bank_name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Account Number */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik02"
                  >
                    <Form.Label className="bold text-muted">
                      شماره حساب
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="account_number"
                      className="form-control-solid number red-focus"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={
                        touched.account_number && !!errors.account_number
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.account_number}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Ref Code */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik03"
                  >
                    <Form.Label className="bold text-muted">
                      کد پیگیری
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="ref_code"
                      className="form-control-solid number red-focus"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.ref_code && !!errors.ref_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ref_code}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Description */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik04"
                  >
                    <Form.Label className="bold text-muted">توضیحات</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      type="text"
                      name="description"
                      className="form-control-solid number red-focus no-resize"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.ref_code}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    variant="primary"
                    size="lg"
                    className="w-100 pill"
                    type="submit"
                  >
                    ارسال اطلاعات
                    {offlineLoading && (
                      <div className="spinner-holder">
                        <Spinner animation="border" variant="white" size="sm" />
                      </div>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center w-100 mt-4">
          <Col md={4}>
            <Button
              variant="primary"
              size="lg"
              className="w-100 pill"
              onClick={() => pay_request(order_id)}
              disabled={!selectedGateway.id}
            >
              پرداخت
              {loading && (
                <div className="spinner-holder">
                  <Spinner animation="border" variant="white" size="sm" />
                </div>
              )}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

PaymentGateWays.propTypes = {
  user: PropTypes.object.isRequired,
  gateWays: PropTypes.array.isRequired,
  set_selected_gateways: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(PaymentGateWays);
