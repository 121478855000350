import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { Modal, Button, Row, Col, Form, Spinner } from "react-bootstrap";

//
import BeforeText from "../../globals/BeforeText";
import Login from "../../../pages/auth/Login";

//
import Comment from "../../../assets/img/comment.png";
import AuthImage from "../../../assets/img/auth.png";

//
import { setProductCommentQuestion } from "../../../redux/features/main/actions";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup.string(),
  body: yup.string().required(),
});

function NewCommentQuestionModal({ id, type, user }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [positiveText, setPositiveText] = useState("");
  const [positiveItems, setPositiveItems] = useState([]);

  const [negativeText, setNegativeText] = useState("");
  const [negativeItems, setNegativeItems] = useState([]);

  /**
   * Submit Form
   */
  const submit_form = (e) => {
    // start loading
    setLoading(true);

    // variable
    let content = e;
    if (type === "comments") {
      content.positive = positiveItems;
      content.negative = negativeItems;
    }

    // request
    setProductCommentQuestion({ id, type, content }).then((res) => {
      if (res) {
        setLoading(false);
        setShow(false);
        if (type === "comments") {
          setPositiveItems([]);
          setNegativeItems([]);
          setPositiveText("");
          setNegativeText("");
        }
      } else {
        setLoading(false);
      }
    });
  };

  /**
   * Add New Positive Item
   */
  const addNewPositiveItem = () => {
    if (positiveText) {
      let positives = [...positiveItems];
      positives.push(positiveText);

      setPositiveItems(positives);

      setPositiveText("");
    }
  };

  /**
   * Add New Negative Item
   */
  const addNewNegativeItem = () => {
    if (negativeText) {
      let negatives = [...negativeItems];
      negatives.push(negativeText);

      setNegativeItems(negatives);

      setNegativeText("");
    }
  };

  /**
   * Remove Positive Item
   */
  const removePositiveItem = (index) => {
    let positives = [...positiveItems];

    positives.splice(index, 1);

    setPositiveItems(positives);
  };

  /**
   * Remove Negative Item
   */
  const removeNegativeItem = (index) => {
    let negatives = [...negativeItems];

    negatives.splice(index, 1);

    setNegativeItems(negatives);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className="new-question-comment"
        onClick={() => setShow(true)}
      >
        ثبت {type === "questions" ? "پرسش" : "نظر"}
      </Button>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        id="new-question-comment-modal"
      >
        <Modal.Header className="no-border" closeButton>
          <BeforeText
            text={`ثبت ${type === "questions" ? "پرسش" : "نظر"}`}
            small
          />
          <div className="extra-info">
            {user.id ? (
              <>
                <small className="text-muted">
                  پیام شما بعد از تایید در سایت نمایش داده میشود.
                </small>
                <small className="text-muted">لطفا فارسی بنویسید.</small>
              </>
            ) : (
              <small>
                جهت ارسال {type === "questions" ? "پرسش" : "نظر"} لطفا ابتدا
                وارد حساب خود شوید
              </small>
            )}
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              {user.id ? (
                <Formik
                  validationSchema={schema}
                  onSubmit={submit_form}
                  initialValues={{
                    title: "",
                    body: "",
                  }}
                >
                  {({ handleSubmit, handleChange, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {/* Title */}
                      {type === "comments" && (
                        <>
                          <Form.Group
                            className="form-group"
                            controlId="validationFormik02"
                          >
                            <Form.Control
                              type="text"
                              name="title"
                              placeholder="موضوع را بیان کنید"
                              className="form-control-solid round red-focus no-resize"
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </Form.Group>

                          {/* Positives */}
                          <Form.Group className="form-group">
                            <div className="input-holder">
                              <Form.Control
                                type="text"
                                name="positiveText"
                                placeholder="ویژگی های مثبت"
                                className="form-control-solid round red-focus no-resize"
                                onChange={(e) =>
                                  setPositiveText(e.target.value)
                                }
                                value={positiveText}
                                autoComplete="off"
                              />

                              <div className="icon-holder">
                                <i
                                  className="fas fa-plus text-success"
                                  onClick={() => addNewPositiveItem()}
                                ></i>
                              </div>
                            </div>

                            <div className="opinion">
                              {positiveItems.map((item, index) => (
                                <p key={index}>
                                  <i className="fas fa-plus text-success"></i>
                                  <span>{item}</span>
                                  <i
                                    className="fas fa-trash-alt text-danger pointer"
                                    onClick={() => removePositiveItem(index)}
                                  ></i>
                                </p>
                              ))}
                            </div>
                          </Form.Group>

                          {/* Negatives */}
                          <Form.Group className="form-group">
                            <div className="input-holder">
                              <Form.Control
                                type="text"
                                name="positiveText"
                                placeholder="ویژگی های منفی"
                                className="form-control-solid round red-focus no-resize"
                                onChange={(e) =>
                                  setNegativeText(e.target.value)
                                }
                                value={negativeText}
                                autoComplete="off"
                              />

                              <div className="icon-holder">
                                <i
                                  className="fas fa-minus text-danger"
                                  onClick={() => addNewNegativeItem()}
                                ></i>
                              </div>
                            </div>

                            <div className="opinion">
                              {negativeItems.map((item, index) => (
                                <p key={index}>
                                  <i className="fas fa-minus text-danger"></i>
                                  <span>{item}</span>
                                  <i
                                    className="fas fa-trash-alt text-danger pointer"
                                    onClick={() => removeNegativeItem(index)}
                                  ></i>
                                </p>
                              ))}
                            </div>
                          </Form.Group>
                        </>
                      )}

                      {/* Message */}
                      <Form.Group
                        className="form-group"
                        controlId="validationFormik03"
                      >
                        <Form.Control
                          as="textarea"
                          rows="5"
                          type="text"
                          name="body"
                          placeholder={`متن ${
                            type === "questions" ? "پرسش" : "نظر"
                          } را وارد کنید ...`}
                          className="form-control-solid round red-focus no-resize"
                          onChange={handleChange}
                          autoComplete="off"
                          isInvalid={touched.body && !!errors.body}
                        />
                      </Form.Group>

                      <Button
                        size="lg"
                        type="submit"
                        variant="primary"
                        className="w-100 text-white pill"
                      >
                        ثبت {type === "questions" ? "پرسش" : "نظر"}
                        {loading && (
                          <div className="spinner-holder">
                            <Spinner
                              animation="border"
                              variant="white"
                              size="sm"
                            />
                          </div>
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Login loginModal />
              )}
            </Col>
            <Col md={6} className="d-none d-md-block">
              {user.id ? (
                <img src={Comment} alt="comment-image" className="img-fluid" />
              ) : (
                <img
                  src={AuthImage}
                  alt="comment-image"
                  className="img-fluid"
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

NewCommentQuestionModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(NewCommentQuestionModal);
