import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

function RangeSlider({ min, max, price_gte, price_lte, onChange }) {
  // Creating the state variables
  const [minVal, setMinVal] = useState(price_gte || min);
  const [maxVal, setMaxVal] = useState(price_lte || max);

  // Creating the refs
  const minValRef = useRef(null);
  const maxValRef = useRef(null);

  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => {
      Math.round(((value - min) / (max - min)) * 100);
    },
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  return (
    <div className="position-relative">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-1"
      />

      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-1"
      />

      <div className="slider">
        <div className="slider__track" />

        <div ref={range} className="slider__range" />
      </div>

      <div className="from-to-price">
        <NumberFormat
          thousandSeparator={true}
          value={minVal}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            // formattedValue = $2,223
            // value ie, 2223
            setMinVal(value);
          }}
        />

        <NumberFormat
          thousandSeparator={true}
          value={maxVal}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            // formattedValue = $2,223
            // value ie, 2223
            setMaxVal(value);
          }}
        />
      </div>
    </div>
  );
}

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RangeSlider;
