import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

//
import Countdown from "react-countdown";
import { Button, Form, Spinner } from "react-bootstrap";

//
import GoogleLogin from "./GoogleLogin";

//
import {
  login,
  sendVerificationCode,
} from "../../../redux/features/user/actions";
import { setUser, setToken } from "../../../redux/features/user/slice";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema_step_1 = yup.object().shape({
  username: yup
    .string()
    .required("لطفا تلفن همراه یا ایمیل خود را وارد کنید")
    .trim()
    .test(
      "is-mobile-or-email",
      "تلفن همراه یا ایمیل وارد شده صحیح نیست",
      (value) => {
        const mobileRegex = /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return mobileRegex.test(value) || emailRegex.test(value);
      }
    ),
});

const schema_step_2 = yup.object().shape({
  code: yup
    .number()
    .required("لطفا کد تایید ارسال شده به تلفن همراه خود را وارد کنید"),
});

function OtpLogin({
  configs,
  setUser,
  setToken,
  cart,
  loginModal,
  setAuthStep,
  closeModalAfterLogin,
}) {
  const [searchParams] = useSearchParams();
  let redirect = searchParams.get("redirect");

  let navigate = useNavigate();

  //
  const [step, setStep] = useState(1);
  const [mobileOrEmail, setMobileOrEmail] = useState("");
  const [loading, setLoading] = useState(false);

  //
  const [sendCode, setSendCode] = useState(false);
  const [timerKey, setTimerKey] = useState(0);

  useEffect(() => {
    setStep(1);

    // eslint-disable-next-line
  }, []);

  /**
   * Renderer callback with condition
   */
  const renderer = ({ minutes, seconds }) => {
    return (
      <p className="text-muted">
        دریافت کد تایید جدید در {minutes}:{seconds}
      </p>
    );
  };

  const timerComplete = () => {
    setSendCode(true);
  };

  /**
   * Send Code
   */
  const send_code = (e) => {
    // start loading
    setLoading(true);

    // variables
    let type = "request-otp";
    let content = e;

    // request
    sendVerificationCode({ type, content }).then((res) => {
      if (res) {
        setLoading(false);
        setStep(2);
        setTimerKey(timerKey + 1);
        setSendCode(false);
      } else {
        setLoading(false);
      }
    });
  };

  /**
   * Login
   */
  const login_user = (e) => {
    // start loading
    setLoading(true);

    // variable
    let otp = true;
    let content = e;
    content.username = mobileOrEmail;

    // request
    login({ otp, content }).then((res) => {
      if (res) {
        setLoading(false);
        setUser(res.customer);
        setToken(res.token.access_token);
        checkForNextStep(res);
      } else {
        setLoading(false);
      }
    });
  };

  /**
   * Check For Next Step
   */
  const checkForNextStep = (res) => {
    if (!cart && !loginModal) {
      navigate(redirect ? redirect : "/");
    } else {
      if (cart) {
        if (!res.customer.first_name && !res.customer.has_password) {
          setAuthStep("noInfo");
        }

        if (!res.customer.first_name && res.customer.has_password) {
          setAuthStep("noName");
        }

        if (res.customer.first_name && !res.customer.has_password) {
          setAuthStep("noPassword");
        }
      }
      if (closeModalAfterLogin) {
        closeModalAfterLogin(res.customer);
      }
    }
  };

  return (
    <>
      {step === 1 ? (
        <Formik
          validationSchema={schema_step_1}
          key={step}
          onSubmit={send_code}
          initialValues={{
            username: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* Mobile Or Email */}
              <Form.Group className="form-group" controlId="validationFormik00">
                <Form.Label className="bold text-muted">
                  مویابل یا ایمیل
                </Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  className="form-control-solid number"
                  onChange={(e) => {
                    handleChange(e);
                    setMobileOrEmail(e.target.value);
                  }}
                  autoComplete="off"
                  isInvalid={touched.username && !!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                className="w-100 pill"
              >
                ارسال کد تایید
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          validationSchema={schema_step_2}
          key={step}
          onSubmit={login_user}
          initialValues={{
            code: "",
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <h6 className="mb-2">کد تایید به {mobileOrEmail} ارسال شد.</h6>
              {/* Code */}
              <Form.Group className="form-group" controlId="validationFormik08">
                <Form.Label className="bold text-muted">کد تایید</Form.Label>
                <Form.Control
                  type="number"
                  name="code"
                  className="form-control-solid"
                  onChange={handleChange}
                  autoComplete="off"
                  isInvalid={touched.code && !!errors.code}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-2">
                {sendCode ? (
                  <p
                    className="text-muted pointer"
                    onClick={() => send_code({ username: mobileOrEmail })}
                  >
                    دریافت کد تایید
                  </p>
                ) : (
                  <Countdown
                    date={Date.now() + 60000}
                    renderer={renderer}
                    zeroPadTime={2}
                    onComplete={timerComplete}
                  />
                )}
              </div>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                className="w-100 pill"
              >
                ورود به {configs.BRAND_NAME}
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      )}

      <GoogleLogin
        text="ورود با گوگل"
        onGoogleLoginSuccess={(user) => checkForNextStep(user)}
      />
    </>
  );
}

OtpLogin.propTypes = {
  setUser: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  cart: PropTypes.bool.isRequired,
  loginModal: PropTypes.bool.isRequired,
  setAuthStep: PropTypes.func,
  closeModalAfterLogin: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

const mapDispatchToProps = {
  setUser,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpLogin);
