import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { Card, Row, Col, Spinner } from "react-bootstrap";

//
import NumberFormat from "react-number-format";
import ShareProductModal from "./ShareProductModal";

// Loading Logo
import Logo from "../../assets/img/logo-polyhub.png";

function Product({
  item,
  showCustomer = false,
  responsive = false,
  horizontal = false,
}) {
  const [currencyTitle] = useCurrency();

  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <Card className={`main-product ${horizontal ? 'horizontal' : ''} ${responsive ? 'responsive' : ''}`}>
      <Card.Body>
        <div className="share" onClick={() => setShowShareModal(true)}>
          <i className="fas fa-share-alt"></i>
        </div>

        <ShareProductModal
          slug={item.slug || ""}
          show={showShareModal}
          setShow={setShowShareModal}
        />

        <Link to={`/product/${item.id}`}>
          <Row>
            <Col xs={responsive || horizontal ? 6 : 12} sm={horizontal ? 6 : 12} className="img-box">
              <div className="img-holder">
                <img src={item.main_image} alt="image" />
              </div>
            </Col>

            <Col
              xs={responsive || horizontal ? 6 : 12}
              sm={horizontal ? 6 : 12}
              className="d-flex flex-column justify-content-between"
            >
              <div className="side-space">
                <Link
                  to={`/product/${item.id}`}
                  className="text-muted text-hover-danger code"
                >
                  {item.code}
                </Link>
                <p className="title">{item.title}</p>
              </div>

              <div
                className={`d-flex align-items-center justify-content-end gap-1 side-space`}
              >
                {item.instance ? (
                  <>
                    <small>{item?.instance?.currency?.title}</small>

                    <h5>
                      <NumberFormat
                        value={+item.instance.original_price}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    </h5>
                  </>
                ) : (
                  <h5>ناموجود</h5>
                )}
              </div>

              {showCustomer && (
                <small className="customer side-space">
                  <i className="fas fa-user-edit text-primary"></i>
                  ارسال توسط تامین کننده
                </small>
              )}
            </Col>
          </Row>
        </Link>
      </Card.Body>
    </Card>
  );
}

Product.propTypes = {
  item: PropTypes.object.isRequired,
  showCustomer: PropTypes.bool,
  responsive: PropTypes.bool,
  horizontal: PropTypes.bool
};

export default Product;
