import React from "react";
import PropTypes from "prop-types";

//
import useCurrency from "../../hooks/useCurrency";

//
import moment from "jalali-moment";
import NumberFormat from "react-number-format";
import { Button, Card } from "react-bootstrap";

function MobileQouteItem({ item, add_to_cart }) {
  const [currencyTitle] = useCurrency();
  
  const statusCalculator = (status) => {
    if (status === "pending_seller") return "در حال بررسی";
    if (status === "pending_customer") return "استعلام شده";
    if (status === "done") return "خریداری شده";
    if (status === "expired") return "منقظی شده";
  };

  return (
    <Card className="panel-product dark-border">
      <Card.Body className="d-flex flex-column justify-content-between">
        <div>
          <div className="img-holder">
            <img src={item.product.main_image} alt="product" />
          </div>

          <div className="product-title">
            <small className="product-code">{item.product.code}</small>
            <small className="product-name">{item.product.title}</small>
          </div>
          <p className="mb-2">{statusCalculator(item.status)}</p>

          <div className="d-flex align-items-center justify-content-between">
            <small className="text-muted">
              {moment(item.created_at, "YYYY/MM/DD")
                .locale("fa")
                .format("YYYY/MM/DD")}
            </small>
            <p>{+item.quantity} عدد</p>
          </div>
        </div>

        {item.final_price && (
          <p className="price">
            <span className="currency-toman">{currencyTitle()}</span>
            <NumberFormat
              value={+item.final_price}
              displayType="text"
              thousandSeparator={true}
              className="ms-1 text-bold text-black"
            />
          </p>
        )}

        <Button
          variant="primary"
          className="w-100 mt-2"
          disabled={item.status !== "pending_customer"}
          onClick={() => add_to_cart(item.id)}
        >
          خرید
        </Button>
      </Card.Body>
    </Card>
  );
}

MobileQouteItem.propTypes = {
  item: PropTypes.object.isRequired,
  add_to_cart: PropTypes.func.isRequired,
};

export default MobileQouteItem;
