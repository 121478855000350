import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import StickyScrollSpyNav from "react-sticky-scrollspy-nav";
import { Accordion } from "react-bootstrap";

//
import Review from "./infos/Review";
import GeneralSpecifications from "./infos/GeneralSpecifications";
import BuyersComments from "./infos/BuyersComments";
import QuestionsAndAnswers from "./infos/QuestionsAndAnswers";
import PriceChart from "./infos/PriceChart";

import themeColors from "../../themeColors";

function AllInfos({ windowSize, product }) {
  const [commentsCount, setCommentsCount] = useState(0);
  let nav = [
    "نقد و بررسی",
    "مشخصات کلی",
    "نظرات خریداران",
    "پرسش و پاسخ",
    "نمودار قیمت",
  ];

  return (
    <div className="all-infos">
      {windowSize > 768 ? (
        <StickyScrollSpyNav
          nav={nav}
          navItemStyle={{
            border: "none",
            borderBottom: "3px solid transparent",
          }}
          navActiveItemStyle={{
            borderBottom: `3px solid ${themeColors.custom_primary}`,
            color: `${themeColors.custom_primary}`,
          }}
        >
          {/* begin: Review */}
          <section ref={React.createRef()} className="item">
            <div className="content">
              <Review product={product} />
            </div>
          </section>
          {/* end: Review */}

          {/* begin: General specifications */}
          <section ref={React.createRef()} className="item">
            <div className="content">
              <GeneralSpecifications
                infos={product.attributes ? product.attributes : []}
              />
            </div>
          </section>
          {/* end: General specifications */}

          {/* begin: Buyers Comments */}
          <section ref={React.createRef()} className="item">
            <div className="content">
              <BuyersComments setCommentsCount={setCommentsCount} />
            </div>
          </section>
          {/* end: Buyers Comments */}

          {/* begin: Question & Answers */}
          <section ref={React.createRef()} className="item">
            <div className="content">
              <QuestionsAndAnswers />
            </div>
          </section>
          {/* end: Question & Answers */}

          {/* begin: Price Chart */}
          <section ref={React.createRef()} className="item" id="price-chart">
            <div className="content">
              <PriceChart product={product} />
            </div>
          </section>
          {/* end: Price Chart */}
        </StickyScrollSpyNav>
      ) : (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>نقد و بررسی</Accordion.Header>
            <Accordion.Body className="item">
              <div className="content">
                <Review product={product} />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>مشخصات کلی</Accordion.Header>
            <Accordion.Body className="item">
              <div className="content">
                <GeneralSpecifications
                  infos={product.attributes ? product.attributes : []}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="w-100 d-flex align-items-center justify-content-between ps-2">
                نظر خریداران
                {!!+commentsCount && (
                  <span className="text-muted">{commentsCount} نظر</span>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body className="item">
              <div className="content">
                <BuyersComments setCommentsCount={setCommentsCount} />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>پرسش و پاسخ</Accordion.Header>
            <Accordion.Body className="item">
              <div className="content">
                <QuestionsAndAnswers />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" id="price-chart">
            <Accordion.Header>نمودار قیمت</Accordion.Header>
            <Accordion.Body className="item">
              <div className="content">
                <PriceChart product={product} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
}

AllInfos.propTypes = {
  windowSize: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(AllInfos);
