import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import { Button, Form, Spinner } from "react-bootstrap";

//
import UserNeedInfo from "./UserNeedInfo";
import GoogleLogin from "../../components/auth/login/GoogleLogin";

//
import { passwordRegister } from "../../redux/features/user/actions";
import { setToken, setUser } from "../../redux/features/user/slice";

//
import BeforeText from "../../components/globals/BeforeText";
import MetaDecorator from "../../components/globals/MetaDecorator";

//
import { Formik } from "formik";
import * as yup from "yup";

//
const schema = yup.object().shape({
  type: yup.string().required(),
  username: yup
    .string()
    .required("لطفا تلفن همراه یا ایمیل خود را وارد کنید")
    .trim()
    .test(
      "is-mobile-or-email",
      "تلفن همراه یا ایمیل وارد شده صحیح نیست",
      (value) => {
        const mobileRegex = /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return mobileRegex.test(value) || emailRegex.test(value);
      }
    ),
  password: yup
    .string()
    .min(6, "رمز عبور باید حداقل 6 کاراکتر باشد")
    .max(64, "رمز عبور باید حداکثر 64 کاراکتر باشد")
    .required("لطفا رمز عبور خود را وارد کنید"),
});

function RegisterPassword({
  configs,
  cart = false,
  setAuthStep,
  setUser,
  setToken,
}) {
  let navigate = useNavigate();
  const [seo, set_seo] = useSeo();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [needPassword, setNeedPassword] = useState(false);

  /**
   * Register Password User
   */
  const register_user = async (e) => {
    // start loading
    setLoading(true);

    // variable
    let content = e;

    // request
    passwordRegister(content).then((res) => {
      if (res) {
        setLoading(false);
        setUser(res.customer);
        setToken(res.token.access_token);

        setNeedPassword(false);
        setStep(2);
      } else {
        setLoading(false);
      }
    });
  };

  /**
   * On Google Login Success
   */
  const onGoogleLoginSuccess = () => {
    setNeedPassword(true);
    setStep(2);
  };

  useEffect(() => {
    if (!cart && configs.BRAND_NAME) {
      set_seo({}, `ثبت نام در ${configs.BRAND_NAME}`);
    }

    // eslint-disable-next-line
  }, [configs]);

  return (
    <>
      {!cart && <MetaDecorator seo={seo} />}
      <div className="register">
        {cart && (
          <>
            <div className="cart-heading-text">
              برای تکمیل فرآیند خرید ابتدا می بایست وارد حساب کاربری خود شوید.
            </div>

            <p className="text-center">
              اگر قبلا ثبت نام کرده اید، وارد شوید.
              <Button
                variant="outline-secondary"
                size="sm"
                className="me-2"
                onClick={() => setAuthStep("login")}
              >
                ورود
              </Button>
            </p>
          </>
        )}
        {!cart && (
          <BeforeText
            text={
              step === 1
                ? `ثبت نام در ${configs.BRAND_NAME}`
                : `بروزرسانی پروفایل در ${configs.BRAND_NAME}`
            }
          />
        )}

        {/* Step 1 */}
        {step === 1 && (
          <>
            <Formik
              validationSchema={schema}
              key={step}
              onSubmit={register_user}
              initialValues={{
                type: "natural",
                username: "",
                password: "",
              }}
            >
              {({ handleSubmit, handleChange, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/* Username */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik00"
                  >
                    <Form.Label className="bold text-muted">
                      مویابل یا ایمیل
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      className="form-control-solid number"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.username && !!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* Password */}
                  <Form.Group
                    className="form-group"
                    controlId="validationFormik06"
                  >
                    <Form.Label className="bold text-muted">
                      رمز عبور
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      className="form-control-solid number"
                      onChange={handleChange}
                      autoComplete="off"
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="w-100 pill"
                  >
                    ثبت نام در {configs.BRAND_NAME}
                    {loading && (
                      <div className="spinner-holder">
                        <Spinner animation="border" variant="white" size="sm" />
                      </div>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>

            <GoogleLogin
              text="ثبت نام با گوگل"
              onGoogleLoginSuccess={() => onGoogleLoginSuccess()}
            />
          </>
        )}

        {/* Step 2 */}
        {step === 2 && <UserNeedInfo cart={cart} password={needPassword} />}

        {!cart && (
          <p className="text-center my-4">
            اگر قبلا ثبت نام کرده اید، وارد شوید.
            <Button
              variant="outline-secondary"
              size="sm"
              className="me-2"
              onClick={() => navigate("/auth/login")}
            >
              ورود
            </Button>
          </p>
        )}
      </div>
    </>
  );
}

RegisterPassword.propTypes = {
  cart: PropTypes.bool,
  setAuthStep: PropTypes.func,
  setUser: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  cart: PropTypes.bool.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

const mapDispatchToProps = {
  setUser,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPassword);
