import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import { Col, Container, Row } from "react-bootstrap";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

function MiddleBanners({ items }) {
  return (
    <div className="middle-banner">
      {/* Box For Up 768px */}

      <Row className="d-none d-md-flex">
        {items.map((item, index) => (
          <Col key={index} className="item banner">
            {item.url.includes("http") ? (
              <a href={item.url} className="w-100">
                <img src={item.image} alt="ad" />
              </a>
            ) : (
              <Link
                to={item.url.charAt(0) == "/" ? item.url : `/${item.url}`}
                className="w-100"
              >
                <img src={item.image} alt="ad" />
              </Link>
            )}
          </Col>
        ))}
      </Row>

      {/* Slider For Down 768px */}
      <div className="d-block d-md-none banner-slider-box">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={10}
          breakpoints={{
            400: {
              slidesPerView: 1.8,
            },
            500: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="item banner">
                {item.url.includes("http") ? (
                  <a href={item.url} className="w-100">
                    <img src={item.image} alt="ad" />
                  </a>
                ) : (
                  <Link
                    to={item.url.charAt(0) == "/" ? item.url : `/${item.url}`}
                    className="w-100"
                  >
                    <img src={item.image} alt="ad" />
                  </Link>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

MiddleBanners.propTypes = {
  items: PropTypes.array.isRequired,
};

export default MiddleBanners;
