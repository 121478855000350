import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Outlet, Navigate } from "react-router-dom";

//
import { Card, Col, Container, Row } from "react-bootstrap";

//
import AuthImage from "../assets/img/auth.png";

//
import { getConfigs } from "../redux/features/main/actions";

function Auth({ configs, getConfigs }) {
  useEffect(() => {
    getConfigs();

    // eslint-disable-next-line
  }, []);

  const token = localStorage.getItem("token");

  return !token ? (
    <div id="auth" className=" py-0 py-md-5">
      <Container>
        <Card className="dark-border">
          <Card.Body>
            <Link to="/" className="logo d-none d-md-block">
              <img src={configs.LOGO_HEADER} alt="logo" />
            </Link>

            <Row>
              <Col lg={6}>
                <Outlet />
              </Col>
              <Col lg={6} className="auth-image d-none d-md-block">
                <img src={AuthImage} alt="auth image" className="img-fluid" />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  ) : (
    <Navigate to="/" replace />
  );
}

Auth.propTypes = {
  configs: PropTypes.object.isRequired,
  getConfigs: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getConfigs,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
