import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";

//
import moment from "jalali-moment";
import { Button, Card, Table } from "react-bootstrap";

//
import { getTickets } from "../../redux/features/user/actions";

//
import NothingToShow from "../../components/globals/NothingToShow";
import BoxLoading from "../../components/globals/BoxLoading";
import Pagination from "../../components/globals/Pagination";
import MetaDecorator from "../../components/globals/MetaDecorator";

function Tickets() {
  let navigate = useNavigate();

  const [seo, set_seo] = useSeo();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState({});

  const convertPeriority = (id) => {
    let ids = {
      1: "کم",
      2: "متوسط",
      3: "زیاد",
    };
    return ids[id];
  };

  const priorityColor = (id) => {
    let ids = {
      1: "success",
      2: "warning",
      3: "danger",
    };
    return ids[id];
  };

  const convertStatus = (status) => {
    let statuses = {
      answered: "پاسخ داده شده",
      pending: "در انتظار",
      processing: "در حال بررسی",
      close: "بسته شده",
    };
    return statuses[status];
  };

  const statusColor = (status) => {
    let statuses = {
      answered: "success",
      pending: "warning",
      processing: "warning",
      close: "danger",
    };
    return statuses[status];
  };

  /**
   * Get Tickets
   */
  const get_tickets = async (page) => {
    // start loading
    setLoading(true);

    // request
    await getTickets(page).then((res) => {
      if (res) {
        setTickets(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    set_seo({}, "تیکت ها");
    get_tickets(1);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div id="tickets">
        <div className="page-title d-flex align-items-center justify-content-between">
          تیکت ها
          <Button
            ariant="primary"
            onClick={() => navigate("/panel/tickets/new")}
          >
            تیکت جدید
          </Button>
        </div>

        {tickets.data && tickets.data.length ? (
          <Card className="dark-border">
            <Card.Body className="panel-item-box">
              <Table responsive borderless className="w-100 mb-0">
                <thead>
                  <tr>
                    <th style={{ minWidth: "120px", width: "120px" }}>موضوع</th>
                    <th style={{ minWidth: "120px", width: "120px" }}>
                      میزان اهمیت
                    </th>
                    <th style={{ minWidth: "120px", width: "120px" }}>وضعیت</th>
                    <th style={{ minWidth: "140px", width: "140px" }}>تاریخ</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets.data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/panel/ticket/${item.id}`}>
                          {item.subject}
                        </Link>
                      </td>
                      <td className={`text-${priorityColor(item.priority)}`}>
                        {convertPeriority(item.priority)}
                      </td>
                      <td className={`text-${statusColor(item.status)}`}>
                        {convertStatus(item.status)}
                      </td>
                      <td>
                        <div className="max-content">
                          {moment(item.created_at, "YYYY/MM/DD")
                            .locale("fa")
                            .format("DD MMMM YYYY")}

                          <small>
                            {moment(item.created_at, "YYYY/MM/DD hh:mm")
                              .locale("fa")
                              .format("hh:mm")}
                          </small>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <Card className="dark-border">
            <Card.Body>
              <NothingToShow text="مورد برای نمایش وجود ندارد" />

              {loading && <BoxLoading />}
            </Card.Body>
          </Card>
        )}

        {tickets.last_page && tickets.last_page > 1 && (
          <Pagination
            currentPage={tickets.current_page - 1}
            lastPage={tickets.last_page}
            changePage={(page) => get_tickets(page.selected + 1)}
          />
        )}
      </div>
    </>
  );
}

export default Tickets;
