import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//
import useSeo from "../../hooks/useSeo";
import useStorage from "../../hooks/useStorage";

//
import { Card, Row, Col, Form, Button, Spinner } from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

// React Dropzone
import { useDropzone } from "react-dropzone";

//
import {
  uploadFile,
  createTicket,
  getDepartments,
} from "../../redux/features/user/actions";

//
import AddImageSvg from "../../assets/img/addphoto.svg";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  department_id: yup.string().required(),
  subject: yup.string().required(),
  priority: yup.string().required(),
  body: yup.string().required(),
});

const priorities = [
  {
    id: 1,
    title: "کم",
  },
  {
    id: 2,
    title: "متوسط",
  },
  {
    id: 3,
    title: "زیاد",
  },
];

function CreateTicket() {
  let navigate = useNavigate();

  const [seo, set_seo] = useSeo();
  const [storageImage] = useStorage();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  /**
   * Get Departments
   */
  const get_departments = async () => {
    await getDepartments().then((res) => {
      if (res) {
        setDepartments(res);
      }
    });
  };

  /**
   * Create Ticket
   */
  const create_ticket = async (e) => {
    // start laoding
    setLoading(true);

    // variable
    let content = { ...e };

    let fd = new FormData();
    let params = new URLSearchParams();
    params.set("server_id", 1);
    params.set("path", "/ticket");

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        fd.append(`files[${i}]`, element);
      }

      // Upload File then Create Ticket
      await uploadFile({params, fd}).then(async (res) => {
        if (res) {
          let uploadedFiles = [];

          for (let i = 0; i < res.length; i++) {
            const element = res[i];
            uploadedFiles.push({
              size: element.size,
              name: element.file,
              type: element.mime_type,
              url: storageImage(`${element.path}/${element.file}`),
            });
          }

          content.files = uploadedFiles;

          await createTicket(content).then((res) => {
            if (res) {
              navigate("/panel/tickets");
            }
          });
        }
      });
    } else {
      // Create Ticket
      await createTicket(content).then((res) => {
        if (res) {
          navigate("/panel/tickets");
        }
      });
    }

    // stop loading
    setLoading(false);
  };

  /**
   * Dropzone
   */
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  /**
   * Remove Image
   */
  const removeImage = (image) => {
    let filesArray = [...files];

    filesArray = filesArray.filter((item) => item != image);

    setFiles(filesArray);
  };

  const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    set_seo({}, "تیکت جدید");
    get_departments();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <MetaDecorator seo={seo} />

      <div className="page-title">تیکت جدید</div>

      <Card className="no-radius dark-border" id="create-ticket">
        <Card.Body className="panel-item-box">
          <Formik
            validationSchema={schema}
            onSubmit={create_ticket}
            initialValues={{
              department_id: "",
              subject: "",
              priority: "1",
              body: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  {/* Subject */}
                  <Col md={6} lg={4}>
                    <Form.Group
                      className="form-group"
                      controlId="validationFormik01"
                    >
                      <Form.Label className="bold">
                        موضوع
                        <span className="text-primary me-1">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        className="form-control-solid big"
                        onChange={handleChange}
                        autoComplete="off"
                        isInvalid={touched.subject && !!errors.subject}
                      />
                    </Form.Group>
                  </Col>

                  {/* Department */}
                  <Col md={6} lg={4}>
                    <Form.Group
                      className="form-group"
                      controlId="validationFormik02"
                    >
                      <Form.Label className="bold">
                        دپارتمان
                        <span className="text-primary me-1">*</span>
                      </Form.Label>
                      <Form.Select
                        className="form-control form-control-solid big"
                        onChange={handleChange}
                        name="department_id"
                        isInvalid={
                          touched.department_id && !!errors.department_id
                        }
                      >
                        {departments.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* Priority */}
                  <Col md={6} lg={4}>
                    <Form.Group
                      className="form-group"
                      controlId="validationFormik03"
                    >
                      <Form.Label className="bold">
                        میزان اهمیت
                        <span className="text-primary me-1">*</span>
                      </Form.Label>
                      <Form.Select
                        className="form-control form-control-solid big"
                        onChange={handleChange}
                        name="priority"
                        isInvalid={touched.priority && !!errors.priority}
                      >
                        {priorities.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {/* Body */}
                  <Col md={12}>
                    <Form.Group
                      className="form-group"
                      controlId="validationFormik04"
                    >
                      <Form.Label className="bold">
                        متن پیام
                        <span className="text-primary me-1">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="4"
                        type="text"
                        name="body"
                        onChange={handleChange}
                        autoComplete="off"
                        className="form-control form-control-solid big right-border"
                        isInvalid={touched.body && !!errors.body}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <section className="dropzone">
                  <div>
                    {files.length ? (
                      <div className="thump-box">
                        {files.map((file, index) => (
                          <div className="thump" key={file.name}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="item">
                                      <img src={file.preview} alt="" />
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <span className="size">
                                      {formatBytes(file.size)}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="icons">
                                      <i
                                        className="fas fa-times-circle text-danger"
                                        onClick={() => removeImage(file)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div {...getRootProps({ className: "input-text" })}>
                        <input {...getInputProps()} />
                        <img src={AddImageSvg} alt="add-image" />
                        <p className="title">فایل</p>
                        <Button variant="dark">اضافه کردن</Button>
                      </div>
                    )}
                  </div>
                </section>

                <Button
                  type="submit"
                  variant="primary"
                  className="text-white submit-button d-block me-auto"
                >
                  ذخیره
                  {loading && (
                    <div className="spinner-holder">
                      <Spinner animation="border" variant="white" size="sm" />
                    </div>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
}

export default CreateTicket;
