import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";

//
import { socials } from "../../constances";

import { Formik } from "formik";
import * as yup from "yup";
import { joinNewsLetter } from "../../redux/features/user/actions";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("ایمیل وارد شده صحیح نیست")
    .required("لطفا ایمیل خود را وارد کنید"),
});

function Footer({ configs, footerLinks, full = false }) {
  const [loading, setLoading] = useState(false);

  const join_news_letter = async (e) => {
    // start loading
    setLoading(true);

    // request
    await joinNewsLetter(e);

    // stop loading
    setLoading(false);
  };

  return (
    <footer id="footer">
      <div className="footer-box">
        {full && (
          <>
            <div className="quick-access">
              <Container>
                <Row>
                  <Col xl={9}>
                    <Row>
                      {Object.entries(footerLinks).map(
                        ([key, value], index) => (
                          <Col xs={6} md={3} key={index}>
                            <div className="box-title">
                              {configs[`FOOTER_TITLE_BLOCK_${key}`]}
                            </div>
                            <ul className="menu-item">
                              {value.map((item, i) => (
                                <li key={i}>
                                  {item.link.includes("http") ? (
                                    <a
                                      href={item.link}
                                      title={item.label}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.label}
                                    </a>
                                  ) : (
                                    <Link to={item.link} title={item.label}>
                                      {item.label}
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>

                  <Col xl={3} className="news-letter-box">
                    <Row>
                      <Col md={6} xl={12} className="newsletter gutter-b">
                        <p>از آخرین اخبار و تخفیف ها با خبر شوید</p>
                        <Formik
                          validationSchema={schema}
                          onSubmit={join_news_letter}
                          initialValues={{
                            email: "",
                          }}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            touched,
                            errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              {/* Mobile Or Email */}
                              <Form.Group className="form-group">
                                <div className="inputcontainer">
                                  <div className="footer-newsletter-box">
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      id="FooterNewsLetter"
                                      onChange={handleChange}
                                      autoComplete="off"
                                      placeholder={configs.CO_EMAIL}
                                      isInvalid={
                                        touched.email && !!errors.email
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.email}
                                    </Form.Control.Feedback>
                                  </div>

                                  <button
                                    type="submit"
                                    className="nl-btn buttonClick"
                                  >
                                    ثبت ایمیل
                                    {loading && (
                                      <div className="spinner-holder">
                                        <Spinner
                                          animation="border"
                                          variant="white"
                                          size="sm"
                                        />
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </Form.Group>
                            </Form>
                          )}
                        </Formik>
                      </Col>

                      <Col md={6} xl={12} className="social-media">
                        <p>
                          {configs.BRAND_NAME} را در شبکه های اجتماعی دنبال کنید
                        </p>
                        <ul>
                          {socials.map((item, index) => (
                            <li key={index}>
                              <a
                                href={configs[item.link]}
                                rel="nofollow noopener"
                                target="_blank"
                                title={item.title}
                              >
                                {item.icon ? (
                                  <i className={item.icon}></i>
                                ) : (
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      `/assets/svg-icons/${item.image}.png`
                                    }
                                    title={item.title}
                                  />
                                )}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            {/* <div className="support-box">
              <Container>
                <Row>
                  <Col md={4} className="item">
                    <i className="fas fa-headset"></i>
                    <span className="text-bold me-1">پشتیبانی</span>{" "}
                    {configs.CO_WORK_HOUR}
                  </Col>

                  <Col md={4} className="item">
                    <i className="fas fa-phone"></i>
                    <span className="font-weight-bold ml-1">شماره تماس : </span>
                    <a
                      className="value"
                      href={`tel:${configs.CO_TELEPHONE}`}
                      title={`تماس با ${configs.BRAND_NAME}`}
                    >
                      {configs.CO_TELEPHONE}
                    </a>
                  </Col>

                  <Col md={4} className="item">
                    <i className="fas fa-envelope"></i>
                    <span className="font-weight-bold ml-1">آدرس ایمیل : </span>
                    <span className="value">{configs.CO_EMAIL}</span>
                  </Col>
                </Row>
              </Container>
            </div> */}
          </>
        )}

        <div className="container">
          <div className="footer-bottom">
            <Row>
              <Col lg={12} className="about">
                <Link
                  to="/"
                  title={configs.BRAND_NAME}
                  className="mb-2 d-flex"
                >
                  <img alt={configs.BRAND_NAME} src={configs.LOGO_FOOTER} />
                </Link>
                <p>{configs.FOOTER_DESCRIPTION}</p>
              </Col>
              {/* <Col lg={6}>
                <ul>
                  <li className="imglnk anjoman">
                    <a href="../static.vitrinnet.com/img/AnjomanCertificate.jpg"></a>
                  </li>
                  <li className="imglnk etehadie">
                    <span onClick="window.open('https://ecunion.ir/verify/vitrinnet.com?token=99272555d8712014841b', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')"></span>
                  </li>
                  <li className="imglnk enamad">
                    <span onClick="window.open('https://trustseal.enamad.ir/?id=105501&amp;Code=Lx9opKjE43JQ7pcERqnP', 'Popup','toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30')"></span>
                  </li>
                  <li className="imglnk daneshbonyan">
                    <a
                      href="https://pub.daneshbonyan.ir/"
                      rel="nofollow noopener"
                      target="_blank"
                    ></a>
                  </li>
                  <li className="imglnk samandehi">
                    <span
                      id="nbqeesgtsizpnbqeesgtoeuk"
                      onClick='window.open("https://logo.samandehi.ir/Verify.aspx?id=209208&amp;p=uiwkobpdpfvluiwkobpdmcsi", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")'
                    ></span>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </div>
          <div className="copy-right">
            <p className="text-center">
              All rights reserved. {configs.BRAND_NAME_EN} ©2022
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  full: PropTypes.bool,
  configs: PropTypes.object.isRequired,
  footerLinks: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
  footerLinks: state.mainReducer.footerLinks,
});

export default connect(mapStateToProps)(Footer);
