import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import MenuIcon from "../../../assets/img/list-menu.svg";

function MobileHeader({ configs, setSidebarStatus }) {
  return (

      <div className="mobile-header d-flex d-lg-none">
        <Link to="/" className="logo">
          <img src={configs.LOGO_HEADER} alt="log" />
        </Link>

        <img
          src={MenuIcon}
          alt="icon"
          onClick={() => setSidebarStatus(true)}
        />
      </div>

  );
}

MobileHeader.propTypes = {
  setSidebarStatus: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(MobileHeader);
