import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//
import { Button, Spinner } from "react-bootstrap";

import Google from "../../../assets/img/google-icon.svg";

//
import { googleLogin } from "../../../redux/features/user/actions";
import { setUser, setToken } from "../../../redux/features/user/slice";

function GoogleLogin({ text, setUser, setToken, onGoogleLoginSuccess }) {
  const [loading, setLoading] = useState(false);

  const loginUser = async (googleUser) => {
    // start loading
    setLoading(true);

    // variables
    let content = {
      auth_code: "",
    };

    for (const [key, value] of Object.entries(googleUser)) {
      for (const [k, v] of Object.entries(value)) {
        if (k === "access_token") {
          content.auth_code = v;
        }
      }
    }

    // request
    await googleLogin(content).then((res) => {
      if (res) {
        setUser(res.customer);
        setToken(res.token.access_token);
        setLoading(false);
        onGoogleLoginSuccess(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    const clientID = process.env.REACT_APP_GOOGLE_LOGIN

    if (!window.gapi) {
      throw new Error(
        '"https://apis.google.com/js/api:client.js" needs to be included as a <script>.'
      );
    }

    if (!clientID) {
      throw new Error("Client Id must be specified.");
    }

    window.gapi.load("auth2", () => {
      const auth2 = window.gapi.auth2.init({
        client_id: clientID,
        scope: "email profile",
        plugin_name: "streamy",
      });
      auth2.attachClickHandler(
        "signinBtn",
        {},
        (googleUser) => {
          loginUser(googleUser);
        },
        (error) => {
          console.log(error);
          // emit("failure", error); // an alias
        }
      );
    });
    // eslint-disable-next-line
  });

  return (
    <Button
      id="signinBtn"
      size="lg"
      type="button"
      variant="outline-secondary"
      className="w-100 pill d-flex align-items-center justify-content-center mt-3"
    >
      <img alt="Logo" src={Google} className="ms-3" height="20px" />

      {text}
      {loading && (
        <div className="spinner-holder">
          <Spinner animation="border" variant="dark" size="sm" />
        </div>
      )}
    </Button>
  );
}

GoogleLogin.propTypes = {
  text: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  onGoogleLoginSuccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setUser,
  setToken,
};

export default connect(null, mapDispatchToProps)(GoogleLogin);
