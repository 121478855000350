import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function WhyUs({ configs }) {
  let items = [
    {
      icon: "fas fa-people-carry",
      text: "مشاوره تخصصی",
    },
    // {
    //   icon: "fas fa-industry",
    //   text: "خرید از تولیدکننده",
    // },
    {
      icon: "fas fa-clipboard-list",
      text: "قیمت بروز",
    },
    {
      icon: "fas fa-money-bill",
      text: "ضمانت بازگشت وجه",
    },
  ];
  return (
    <Row className="why-us d-none d-md-flex">
      <Col xs="9">
        <Card>
          <Card.Body>
            <Row>
              {items.map((item, index) => (
                <Col key={index} className="item">
                  <span>
                    <i className={item.icon}></i>
                  </span>
                  <p>{item.text}</p>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs="3">
        <Link to="/" className="item link">
          <span>
            <i className="fas fa-user-shield"></i>
          </span>
          <p>چرا از {configs.BRAND_NAME} خرید کنیم ؟</p>
        </Link>
      </Col>
    </Row>
  );
}

WhyUs.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(WhyUs);
