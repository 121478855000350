

const useStorage = () => {
    const storageURL = process.env.REACT_APP_STORAGE_URL

    /**
     * Check User Telegram for Connected Or Not
     */
    const storageImage = (link) => {
        return `${storageURL}${link}`
    };

    return [storageImage];
};

export default useStorage;