import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//
import moment from "jalali-moment";
import NumberFormat from "react-number-format";
import { Card, Dropdown, Row, Col } from "react-bootstrap";

//
import Product from "./Product";

import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

//
import { addToCart } from "../../redux/features/cart/actions";

//
import ImageLoading from "../../assets/img/product/LoadingProduct.gif";

function OrdersList({
  items,
  date,
  windowSize,
  swiper = false,
  moreInfo = false,
}) {
  const [currencyTitle] = useCurrency();

  /**
   * Add To Cart
   */
  const add_to_cart = async (product_instance_id, quantity) => {
    // variable
    let content = {
      product_instance_id,
      quantity,
    };

    // request
    await addToCart(content).then((res) => {
      if (res) {
        toast.success("محصول به سبد خرید شما افزوده شد", {
          theme: "colored",
          icon: <i className="fas fa-bell toast_icons"> </i>,
          closeButton: (
            <i className="fas fa-times-circle toast_close_button toast_icons"></i>
          ),
        });
      }
    });
  };

  return (
    <div className="order-list">
      {windowSize > 768 ? (
        <Card className="dark-border">
          <Card.Body className="panel-item-box">
            <table>
              <thead>
                <tr>
                  <th width="15%"></th>
                  <th width="15%"></th>
                  <th width="21%"></th>
                  <th width="16%"></th>
                  <th width="10%"></th>
                  <th width="10%"></th>
                  {moreInfo && <th width="5%"></th>}
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <img
                        className="img-fluid"
                        src={item.product_instance.product.main_image}
                        alt={item.product_instance.product.title}
                        onError={ImageLoading}
                      />
                    </td>
                    <td>
                      <p className="code">
                        {item.product_instance.product.code}
                      </p>
                      {date && (
                        <p className="text-muted">
                          {moment(date, "YYYY/MM/DD HH:mm")
                            .locale("fa")
                            .format("YYYY/MM/DD HH:mm")}
                        </p>
                      )}
                    </td>
                    <td className="text-center">
                      <Link
                        to={`/product/${item.product_instance.product.id}`}
                        className="product-name text-muted"
                      >
                        {item.product_instance.product.title}
                      </Link>
                    </td>
                    <td>
                      <p className="order-price d-flex align-items-center gap-1">
                        <span className="currency-toman">
                          {currencyTitle()}
                        </span>
                        <h6 className="price-Separate">
                          <NumberFormat
                            value={+item.final_price}
                            displayType="text"
                            thousandSeparator={true}
                            className="ms-1 text-bold text-black"
                          />
                        </h6>
                      </p>
                    </td>
                    <td>
                      <p className="order-number">{item.quantity} عدد</p>
                    </td>
                    <td>
                      <p
                        className="text-primary cursor-pointer"
                        onClick={() =>
                          add_to_cart(item.product_instance.id, item.quantity)
                        }
                      >
                        سفارش مجدد
                      </p>
                    </td>

                    {moreInfo && (
                      <td>
                        <Dropdown className="other-link" drop="start">
                          <Dropdown.Toggle variant="light">
                            <i className="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>مشاهده بیجک</Dropdown.Item>
                            <Dropdown.Item>ثبت نظر</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      ) : (
        <>
          {swiper ? (
            <Card className="dark-border">
              <Card.Body className="panel-item-box">
                <Swiper
                  slidesPerView={1.5}
                  spaceBetween={10}
                  breakpoints={{
                    576: {
                      slidesPerView: 2.5,
                    },
                  }}
                >
                  {items.map((item) => (
                    <SwiperSlide key={item.id}>
                      <Product
                        quantity={item.quantity}
                        price={item.final_price}
                        item={item.product_instance.product}
                        moreInfo={moreInfo}
                        date={item.created_at}
                        product_instance_id={item.product_instance.id}
                        reorder
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Card.Body>
            </Card>
          ) : (
            <Row>
              {items.map((item, index) => (
                <Col sm={4} key={index} className="gutter-b">
                  <Product
                    quantity={item.quantity}
                    price={item.final_price}
                    item={item.product}
                    moreInfo={moreInfo}
                    date={item.created_at}
                  />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </div>
  );
}

OrdersList.propTypes = {
  swiper: PropTypes.bool,
  moreInfo: PropTypes.bool,
  windowSize: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.themeReducer.windowSize,
});

export default connect(mapStateToProps)(OrdersList);
