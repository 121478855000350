import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import { updateUserInfo } from "../../redux/features/user/actions";
import { setUser } from "../../redux/features/user/slice";

//
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email(),
  identify_code: yup.number().required(),
  mobile: yup
    .string()
    .required()
    .trim()
    .matches(
      /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g,
      "Phone number is not valid"
    ),
});

function UpdateUserInfo({ user, setUser }) {
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    identify_code: "",
  });

  const updateInitialValues = () => {
    let values = { ...initialValues };

    for (const [userKey, userValue] of Object.entries(user)) {
      for (const [key, value] of Object.entries(values)) {
        if (userValue && userKey === key) {
          values[key] = userValue;
        }
      }
    }

    setInitialValues(values);
  };

  useEffect(() => {
    updateInitialValues();
  }, [user]);

  /**
   * Update User Info
   */
  const update_info = async (e) => {
    // start loading
    setLoading(true);

    // variable
    let fd = new FormData();

    fd.append("type", "natural");
    fd.append("_method", "put");

    for (const [key, value] of Object.entries(e)) {
      fd.append(key, value);
    }

    // request
    await updateUserInfo({ fd }).then((res) => {
      if (res) {
        setUser(res);
      }
    });

    // stop loading
    setLoading(false);
  };

  return (
    <div className="update-user-info">
      <Formik
        validationSchema={schema}
        onSubmit={update_info}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, touched, errors, values }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              {/* First Name */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik01"
                >
                  <Form.Label className="bold">
                    نام
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    className="form-control-solid big"
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.first_name}
                    isInvalid={touched.first_name && !!errors.first_name}
                  />
                </Form.Group>
              </Col>

              {/* Last Name */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik02"
                >
                  <Form.Label className="bold">
                    نام خانوادگی
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    className="form-control-solid big"
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.last_name}
                    isInvalid={touched.last_name && !!errors.last_name}
                  />
                </Form.Group>
              </Col>

              {/* Email */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik03"
                >
                  <Form.Label className="bold">ایمیل</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    className="form-control-solid big "
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                </Form.Group>
              </Col>

              {/* ID number */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik04"
                >
                  <Form.Label className="bold">
                    کد ملی
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="identify_code"
                    className="form-control-solid big "
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.identify_code}
                    isInvalid={touched.identify_code && !!errors.identify_code}
                  />
                </Form.Group>
              </Col>

              {/* Mobile */}
              <Col md={6} lg={4}>
                <Form.Group
                  className="form-group"
                  controlId="validationFormik05"
                >
                  <Form.Label className="bold">
                    تلفن همراه
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    className="form-control-solid big number "
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.mobile}
                    isInvalid={touched.mobile && !!errors.mobile}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              type="submit"
              variant="primary"
              className="text-white submit-button d-block me-auto"
            >
              ذخیره
              {loading && (
                <div className="spinner-holder">
                  <Spinner animation="border" variant="white" size="sm" />
                </div>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

UpdateUserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setUser,
};

const mapStateToProp = (state) => ({
  user: state.userReducer.user,
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateUserInfo);
