import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//
import useSeo from "../../hooks/useSeo";

//
import ReCAPTCHA from "react-google-recaptcha";

//
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

//
import MetaDecorator from "../../components/globals/MetaDecorator";

//
import { sendFreeConsultationForm } from "../../redux/features/user/actions";

//
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("ایمیل وارد شده صحیح نیست")
    .required("لطفا ایمیل خود را وارد کنید"),
  mobile: yup
    .string()
    .required("لطفا تلفن همراه خود را وارد کنید")
    .trim()
    .matches(
      /^09[0-3][0-9]-?[0-9]{3}-?[0-9]{4}$/g,
      "تلفن همراه وارد شده صحیح نیست"
    ),
  message: yup
    .string()
    .min(3, "پیام باید حداقل 3 کاراکتر باشد")
    .required("لطفا پیام خود را وارد کنید"),
});

const reCaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA;
function FreeConsultation({ configs, user }) {
  const [seo, set_seo] = useSeo();

  const [loading, setLoading] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [captchaKey, setCaptchaKey] = useState(0);

  const [initialValues, setInitialValues] = useState({
    email: "",
    mobile: "",
    message: "",
  });

  const updateInitialValues = () => {
    if (user.id) {
      let values = { ...initialValues };

      values.email = user.email;
      values.mobile =
        user.mobile.charAt(0) === "0" ? user.mobile : `0${user.mobile}`;

      setInitialValues(values);
    }
  };

  const onChange = (e) => {
    if (e) {
      setCaptcha(e);
    } else {
      setCaptcha("");
    }
  };

  useEffect(() => {
    updateInitialValues();
  }, [user]);

  /**
   * Send Message
   */
  const send_message = async (e) => {
    // start loading
    setLoading(true);
    let content = { ...e };
    content.recaptcha = captcha;

    // request
    await sendFreeConsultationForm(content).then((res) => {
      if (!res) {
        setCaptchaKey(captchaKey + 1);
        onChange("");
      }
    });

    // stop loading
    setLoading(false);
  };

  useEffect(() => {
    set_seo({}, "مشاوره رایگان");
    // eslint-disable-next-line
  }, []);

  return (
    <div id="free-consultation">
      <MetaDecorator seo={seo} />

      <Container>
        <div className="main-title">مشاوره رایگان</div>

        {/* Form */}
        <Card className="dark-border no-radius">
          <Card.Body>
            <Row>
              {configs.CONSULTATION_TEXT && (
                <Col md={4}>
                  <p className="text-content">{configs.CONSULTATION_TEXT}</p>
                </Col>
              )}

              <Col md={configs.CONSULTATION_TEXT ? 8 : 12}>
                <Formik
                  validationSchema={schema}
                  onSubmit={send_message}
                  initialValues={initialValues}
                  enableReinitialize
                >
                  {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    values,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        {/* Email */}
                        <Col sm={6}>
                          <Form.Group
                            className="form-group"
                            controlId="validationFormik01"
                          >
                            <Form.Label className="bold text-muted">
                              ایمیل
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              className="form-control-solid"
                              onChange={handleChange}
                              autoComplete="off"
                              value={values.email}
                              isInvalid={touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        {/* Mobile */}
                        <Col sm={6}>
                          <Form.Group
                            className="form-group"
                            controlId="validationFormik00"
                          >
                            <Form.Label className="bold text-muted">
                              موبایل
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="mobile"
                              className="form-control-solid number"
                              onChange={handleChange}
                              autoComplete="off"
                              value={values.mobile}
                              isInvalid={touched.mobile && !!errors.mobile}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.mobile}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Message */}
                      <Form.Group
                        className="form-group"
                        controlId="validationFormik03"
                      >
                        <Form.Label className="bold text-muted">
                          پیام
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="7"
                          type="text"
                          name="message"
                          className="form-control-solid no-resize"
                          onChange={handleChange}
                          autoComplete="off"
                          isInvalid={touched.message && !!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="mb-3 d-flex">
                        <ReCAPTCHA
                          key={captchaKey}
                          className="google-captcha"
                          sitekey={reCaptchaSiteKey}
                          onChange={onChange}
                        />
                      </div>

                      <Button
                        type="submit"
                        variant="primary"
                        className="text-white"
                        disabled={!captcha}
                      >
                        ارسال پیام
                        {loading && (
                          <div className="spinner-holder">
                            <Spinner
                              animation="border"
                              variant="white"
                              size="sm"
                            />
                          </div>
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

FreeConsultation.prototypes = {
  configs: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
  user: state.userReducer.user,
});

export default connect(mapStateToProps)(FreeConsultation);
