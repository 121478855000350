import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//
import { Button, Container } from "react-bootstrap";

//
import MobileMenu from "./MobileMenu";

//
import { blogSocials } from "../../../constances";

function BlogHeader({ configs }) {
  let navigate = useNavigate();

  return (
    <Container>
      <header id="header">
        <MobileMenu />

        <Link to="/blog">
          <img src={configs.LOGO_HEADER} alt="logo" className="logo" />
        </Link>

        <Button
          variant="outline-primary"
          className="back-to-app d-none d-lg-block"
          onClick={() => navigate("/")}
        >
          مشاهده محصولات
        </Button>

        <div className="socials d-block d-lg-none">
          {blogSocials.map((item, index) => (
            <a
              href={configs[item.link]}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              className="ms-2"
            >
              <i className={`text-muted ${item.icon}`}></i>
            </a>
          ))}
        </div>
      </header>
    </Container>
  );
}

BlogHeader.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(BlogHeader);
