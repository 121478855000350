import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//
import { Row, Col } from "react-bootstrap";

function ShairSection({ configs }) {
  const [showContent, setShowContent] = useState(false);

  return (
    <div>
      {showContent && (
        <div className="click-box " onClick={() => setShowContent(false)}></div>
      )}

      <div className="share-section ">
        <div
          className="share-section-button"
          onClick={() => setShowContent(true)}
        >
          <i className="fas fa-phone-volume"></i>
        </div>

        <div className={`share-section-content ${showContent ? "active" : ""}`}>
          <div className="title">مشاوره رایگان</div>
          <div className="item">
            <a href={`tel:${configs.CO_TELEPHONE}`}>
              <i className="fas fa-phone"></i>
              <span>
                مشاوره تلفنی
                <small className="working-hours">
                  ({configs.CO_WORK_HOUR})
                </small>
              </span>
            </a>
          </div>

          <div className="item  d-block d-md-none">
            <a
              href={`https://api.whatsapp.com/send?phone=${configs.WHATSAPP_SUPPORT}`}
              rel="nofollow noopener"
              target="_blank"
            >
              <i className="fab fa-whatsapp"></i>
              <span>مشاوره از طریق واتس اپ </span>
            </a>
          </div>

          <div className="item">
            <Link
              to="/free-consultation"
              onClick={() => setShowContent(false)}
            >
              <i className="fas fa-comment-dots"></i>
              <span> ثبت درخواست مشاوره </span>
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="share-section-mobile share-section ">
        <div className="share-section-mobile-content">
          <div className="phone-consultancy w-40">
            <a className="phone-consultancy-mobile" href="tel:02191001170">
              <i className="fas fa-phone"></i>
              <span> مشاوره رایگان </span>
            </a>
          </div>
          <div className="connect-whatsApp w-50">
            <Link to="/" title="درخواست مشاوره رایگان">
              <i className="far fa-comment-dots"></i>
              <span>با من تماس بگیرید </span>
            </Link>
          </div>
          <div className="consultation-request w-20">
            <a
              href="https://api.whatsapp.com/send?phone=989921603901&amp;text=%d8%a2%d8%af%d8%b1%d8%b3_%d8%b5%d9%81%d8%ad%d9%87_%d8%a7%db%8c_%da%a9%d9%87_%d9%85%d8%ad%d8%b5%d9%88%d9%84_%d8%b1%d8%a7_%d9%85%d8%b4%d8%a7%d9%87%d8%af%d9%87_%da%a9%d8%b1%d8%af%d9%85:%0a_https://vitrinnet.com/"
              rel="nofollow noopener"
              target="_blank"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}

ShairSection.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(ShairSection);
