import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//
import { Col, Row } from "react-bootstrap";

function AdBanners({ items }) {
  return (
    <div className="ad-banners">
      <Row>
        {items.map((item, index) => (
          <Col md key={index}>
            {item.url.includes("http") ? (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex"
              >
                <img src={item.image} alt="ad" className="img-fluid" />
              </a>
            ) : (
              <Link
                to={item.url.charAt(0) == "/" ? item.url : `/${item.url}`}
                className="d-flex"
              >
                <img src={item.image} alt="ad" className="img-fluid" />
              </Link>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
}

AdBanners.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AdBanners;
