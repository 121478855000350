import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { createSearchParams, useNavigate } from "react-router-dom";

//
import useCurrency from "../../hooks/useCurrency";

//
import { Accordion, Button, Form } from "react-bootstrap";

//
import RangeSlider from "./RangeSlider";
import SkeletopBox from "../globals/SkeletopBox";

function Filter({ queries, info, loading, min_price, max_price, attributes }) {
  const navigate = useNavigate();

  const [currencyTitle] = useCurrency();

  // const [attributes, setAttributes] = useState([]);
  const [minMax, setMinMax] = useState({
    min: 0,
    max: 0,
  });

  const [searches, setSearches] = useState({});

  const filteredSearch = useCallback(
    (items, type, searchKey) => {
      let optionList = { ...items };
      let option = searches[searchKey];

      if (!option) {
        return Object.entries(optionList);
      }

      option = option.trim().toLowerCase();

      optionList = Object.entries(optionList).filter(([key, value]) => {
        console.log(key, value);
        if (type === "color") {
          if (value?.name?.name.toLowerCase().indexOf(option) !== -1) {
            return value;
          }
        } else {
          if (value.name.toLowerCase().indexOf(option) !== -1) {
            return value;
          }
        }
      });

      // Return an array with the filtered data
      return optionList;
    },
    [searches]
  );

  const changeSearch = (text, key) => {
    let searchObject = { ...searches };
    searchObject[key] = text;

    setSearches(searchObject);
  };

  const changeQuery = (info) => {
    let params = { ...queries };

    for (let i = 0; i < info.length; i++) {
      const element = info[i];

      params[element.key] = element.value;

      if (!params[element.key]) {
        delete params[element.key];
      }
    }

    navigate({
      pathname: "/search",
      search: createSearchParams(params).toString(),
    });
  };

  const changeAttr = (parentId, childId) => {
    console.log(parentId, childId);
    let attrs = { ...attributes };

    attrs[parentId].available_values[childId].value =
      !attrs[parentId].available_values[childId].value;

    let newAttrs = [];
    for (const [mainKey, mainValue] of Object.entries(attrs)) {
      let id = mainKey;
      let values = [];

      for (const [key, value] of Object.entries(mainValue.available_values)) {
        if (value.value) {
          values.push(key);
        }
      }

      if (values.length) {
        newAttrs.push({ id, values });
      }
    }

    changeQuery([
      {
        key: "attributes",
        value: newAttrs.length ? JSON.stringify(newAttrs) : "",
      },
    ]);
  };

  return (
    <>
      {loading ? (
        <SkeletopBox height={300} />
      ) : (
        <div className="filters">
          {/* Children */}
          {!!(info.children && info.children.length) && (
            <Accordion defaultActiveKey="0" className="space">
              <Accordion.Item eventKey="0">
                <Accordion.Header> زیر دسته های {info.title}</Accordion.Header>
                <Accordion.Body className="children">
                  {info.children.map((item) => (
                    <a
                      className="cursor-pointer"
                      onClick={() =>
                        changeQuery([{ key: "category_id", value: item.id }])
                      }
                    >
                      {item.title} (
                      {item.total_products ? item.total_products : 0})
                    </a>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/* Price */}
          {!!max_price && (
            <Accordion defaultActiveKey="0" className="space">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  قیمت
                  <span>({currencyTitle()})</span>
                </Accordion.Header>
                <Accordion.Body>
                  <RangeSlider
                    key={min_price}
                    min={min_price}
                    max={max_price}
                    price_gte={queries.price_gte || 0}
                    price_lte={queries.price_lte || 0}
                    onChange={({ min, max }) => setMinMax({ min, max })}
                  />
                  <Button
                    variant="primary"
                    className="price-btn"
                    onClick={() =>
                      changeQuery([
                        { key: "price_gte", value: minMax.min },
                        { key: "price_lte", value: minMax.max },
                      ])
                    }
                  >
                    اعمال فیلتر قیمت
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {/* Just availables */}
          <div className="filter-box">
            <Form.Check
              type="switch"
              id="just-available"
              label="فقط کالاهای موجود"
              checked={+queries.only_available ? true : false}
              onChange={() =>
                changeQuery([
                  {
                    key: "only_available",
                    value: +queries.only_available ? 0 : 1,
                  },
                ])
              }
            />
          </div>

          {/* Attribiutes */}
          {Object.entries(attributes).map(([mainKey, mainValue]) => (
            <>
              {Object.keys(mainValue.available_values).length > 1 && (
                <Accordion key={mainKey}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{mainValue.title}</Accordion.Header>
                    <Accordion.Body>
                      {Object.keys(mainValue.available_values).length > 20 && (
                        <div className="search-holder">
                          <Form.Control
                            type="text"
                            className="form-control-solid"
                            placeholder="جستجو..."
                            value={searches[mainKey]}
                            onChange={(e) =>
                              changeSearch(e.target.value, mainKey)
                            }
                          />
                        </div>
                      )}
                      <div className="items">
                        {filteredSearch(
                          mainValue.available_values,
                          mainValue.value_format,
                          mainKey
                        ).length > 0 ? (
                          <>
                            {filteredSearch(
                              mainValue.available_values,
                              mainValue.value_format,
                              mainKey
                            ).map(([key, value]) => (
                              <Form.Check
                                key={key}
                                type="switch"
                                id={key}
                                label={
                                  mainValue.value_format === "color"
                                    ? value?.name?.name
                                    : value.name
                                }
                                checked={value.value}
                                onChange={() => changeAttr(mainKey, key)}
                              />
                            ))}
                          </>
                        ) : (
                          <div className="no-data">نتیجه یافت نشد</div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </>
          ))}
        </div>
      )}
    </>
  );
}

Filter.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  min_price: PropTypes.number.isRequired,
  max_price: PropTypes.number.isRequired,
  setSelectedMinMax: PropTypes.func.isRequired,
};

export default Filter;
