import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//
import { Container } from "react-bootstrap";

function CartHeader({configs}) {
  const [showHeader, setShowHeader] = useState(true);
  const [y, setY] = useState(window.scrollY);

  /**
   * handle Scroll to top
   */
  const handleNavigation = (e) => {
    const window = e.currentTarget;

    // show scroll to top when page scroll is more than 100
    if (y < window.scrollY && window.pageYOffset > 100) {
      setShowHeader(false);
    }
    if (y > window.scrollY) {
      setShowHeader(true);
    }

    setY(window.scrollY);
  };

  // detect page scrool
  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  return (
    <header className={`cart-header ${showHeader ? "show" : "hide"}`}>
      <Container>
        <Link to="/">
          <img src={configs.LOGO_HEADER} alt="logo" className="img-fluid" />
        </Link>
      </Container>
    </header>
  );
}

CartHeader.propTypes = {
  configs : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  configs : state.mainReducer.configs
})

export default connect(mapStateToProps)(CartHeader);
