import React from "react";
import PropTypes from "prop-types";

function InfoItems({ infos }) {
  return (
    <div className="infos">
      {infos.slice(0, 5).map((item, i) => (
        <div className="item" key={i}>
          <i className="circle-empty"></i>
          <small className="ms-1">{item.key}: </small>
          <small>{item.value}</small>
        </div>
      ))}
    </div>
  );
}

InfoItems.propTypes = {
  infos: PropTypes.object.isRequired,
};

export default InfoItems;
