import axios from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Get Currencies
 */
export const getCurrencies = createAsyncThunk("getCurrencies", async () => {
  try {
    const { data } = await axios.get("shop/common/currencies");

    //
    return data.result;
  } catch (error) {
    return false;
  }
});

/**
 * Get Configs
 */
export const getConfigs = createAsyncThunk("getConfigs", async () => {
  try {
    const { data } = await axios.get("shop/configs");

    //
    return data.result;
  } catch (error) {
    return false;
  }
});


/**
 * Get Footer Menus
 */
export const getFooterMenus = createAsyncThunk("getFooterMenus", async () => {
  try {
    const { data } = await axios.get("shop/menu-items/footer");

    //
    return data.result;
  } catch (error) {
    return false;
  }
});

/**
 * Get Configs
 */
export const getHomeInfo = async () => {
  try {
    const { data } = await axios.get("shop/v4/home", {
      params: {
        scope: 'site',
        per_page: 10
      }
    });

    //
    return data.result.data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Categories
 */
export const getCategories = createAsyncThunk("getCategories", async () => {
  try {
    const { data } = await axios.get("shop/v4/categories/1/tree");

    //
    return data.result.children;
  } catch (error) {
    return false;
  }
});

/**
 * Get Featured Products
 */
export const getFeaturedProducts = async () => {
  try {
    const { data } = await axios.get("shop/v4/products/featured", {
      params: {
        page: 1,
        per_page: 6,
      },
    });

    //
    return data.result.data;
  } catch (error) {
    return false;
  }
};

/**
 * Get Home Banners
 */
export const getBanners = async () => {
  try {
    const { data } = await axios.get("shop/banners");

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Category Blocks
 */
export const getCategoryBlocks = async () => {
  try {
    const { data } = await axios.get("shop/categories/blocks");

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Category Info
 * @param {category slug} payload
 */
export const getCategoryInfo = async (payload) => {
  try {
    const { data } = await axios.get(`shop/v4/categories/${payload}`);

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Search Product
 * @param {search params} params
 */
export const searchProduct = async (params) => {
  try {
    const { data } = await axios.get(`shop/v4/products/search`, {
      params,
    });

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Search Suggested Product
 * @param {search params} params
 */
export const searchSuggestion = async (params) => {
  try {
    const { data } = await axios.get(`shop/v4/products/get-search-suggestions`, {
      params,
    });

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product
 * @param {product slug} payload
 */
export const getProduct = async (payload) => {
  try {
    const { data } = await axios.get(`shop/v4/products/${payload}`);

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Comment
 * @param {product slug , page} payload
 */
export const getProductComments = async (payload) => {
  try {
    const { data } = await axios.get(`shop/v4/product-comments/${payload.slug}`, {
      params: {
        page: payload.page,
      },
    });

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Set Product Comment Question
 * @param {product_id, type: questions || comments, body} payload
 */
export const setProductCommentQuestion = async (payload) => {
  try {
    await axios.post(`shop/products/${payload.id}/${payload.type}`, payload.content);

    //
    toast.success(
      `${payload.type === "questions" ? "پرسش" : "نظر"} شما با موفقیت ارسال شد`,
      {
        theme: "colored",
        icon: <i className="fas fa-bell toast_icons"></i>,
        closeButton: (
          <i className="fas fa-times-circle toast_close_button toast_icons"></i>
        ),
      }
    );

    //
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Questions
 * @param {product slug , page} payload
 */
export const getProductQuestions = async (payload) => {
  try {
    const { data } = await axios.get(`shop/products/${payload.slug}/questions`, {
      params: {
        page: payload.page,
      },
    });

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Prices
 * @param {product slug, selected options} payload
 */
export const getProductPrices = async (payload) => {
  try {
    const { data } = await axios.get(`shop/products/${payload}/prices`);

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Instances
 */
export const getProductInstance = async (payload) => {
  try {
    const { data } = await axios.get(
      `shop/v4/products/${payload.id}/${payload.selectOptions}`
    );

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Product Price Chart
 * @param {product slug} payload
 */
export const getProductPriceChart = async (payload) => {
  try {
    const { data } = await axios.get(`shop/products/${payload}/price-chart`);

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Related Products
 * @param {product id} payload
 */
export const getRelatedProducts = async (payload) => {
  try {
    const { data } = await axios.get(`shop/v4/products/${payload}/related`);

    //
    return data.result.data;
  } catch (error) {
    return false;
  }
};

/**
 * Get FAQs
 * @param {categury_id or type: seller || customer} payload
 * @returns
 */
export const getFAQ = async (payload) => {
  try {
    const { data } = await axios.get("shop/frequent-questions", {
      params: payload,
    });

    //
    let faq = data.result;

    //
    return faq;
  } catch (error) {
    return false;
  }
};

/**
 * Get Static Page
 * @param {page slug} payload
 */
export const getStaticPage = async (payload) => {
  try {
    const { data } = await axios.get(`shop/pages/${payload}`);

    //
    return data.result;
  } catch (error) {
    return false;
  }
};

/**
 * Get Contries
 */
export const getCountries = async () => {
  try {
    const { data } = await axios.get("shop/locations/countries");

    //
    let countries = data.result;
    let list = [];

    //
    for (let i = 0; i < countries.length; i++) {
      const element = countries[i];
      list.push({
        label: element.title,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};

/**
 * Get Provinces OF Country
 * @param {country_id} payload
 */
export const getProvinces = async (payload) => {
  try {
    const { data } = await axios.get(
      `shop/locations/countries/${payload}/provinces`
    );

    //
    let province = data.result;
    let list = [];

    //
    for (let i = 0; i < province.length; i++) {
      const element = province[i];
      list.push({
        label: element.name,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};

/**
 * Get Cities OF Province
 * @param {province_id} payload
 */
export const getCities = async (payload) => {
  try {
    const { data } = await axios.get(`shop/locations/provinces/${payload}/cities`);

    //
    let cities = data.result;
    let list = [];

    //
    for (let i = 0; i < cities.length; i++) {
      const element = cities[i];
      list.push({
        label: element.name,
        value: element.id,
      });
    }

    return list;
  } catch (error) {
    return false;
  }
};

/**
 * Get Version Info For Application Download
 */
export const getVersionInfo = async () => {
  try {
    const { data } = await axios.get(`shop/v4/version-info`);

    return data.result
  } catch (error) {
    return false;
  }
}
