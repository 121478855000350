import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//
import Pagination from "../../globals/Pagination";
import SkeletopBox from "../../globals/SkeletopBox";
import NewCommentQuestionModal from "./NewCommentQuestionModal";

//
import { getProductQuestions } from "../../../redux/features/main/actions";

function QuestionsAndAnswers({ configs }) {
  let { slug } = useParams();

  const [questions, setQuestions] = useState({});
  const [loading, setLoading] = useState(false);

  /**
   * Get Comments
   */
  const get_product_questions = async (page) => {
    // request
    await getProductQuestions({ slug, page }).then((res) => {
      if (res) {
        setQuestions(res);
      }
    });
  };

  useEffect(async () => {
    // start loading
    setLoading(true);

    await get_product_questions(1);

    // stop loading
    setLoading(false);
  }, [slug]);

  return (
    <div className="question-answers">
      <p className="title">پرسش و پاسخ</p>

      <NewCommentQuestionModal id={slug} type="questions" />

      {loading ? (
        <>
          <p className="mb-1">
            <SkeletopBox height={25} width={150} />
          </p>
          <p>
            <SkeletopBox height={25} width={250} />
          </p>
        </>
      ) : (
        <>
          {questions.result &&
            questions.result.map((item) => (
              <div className="question-box" key={item.id}>
                <div className="question">
                  <i className="far fa-question-circle"></i>
                  <div>
                    <small className="name text-muted">
                      {item.customer.first_name} {item.customer.last_name}
                    </small>
                    {item.body && <p>{item.body}</p>}
                  </div>
                </div>

                {item.answer && (
                  <div className="answer">
                    <small className="name text-muted d-flex align-items-center">
                      <p className="ms-2 text-primary">پاسخ</p>
                      {configs.BRAND_NAME}
                    </small>
                    <p>{item.answer.body}</p>
                  </div>
                )}
              </div>
            ))}

          {questions.last_page && questions.last_page > 1 && (
            <Pagination
              currentPage={+questions.current_page - 1}
              lastPage={questions.last_page}
              changePage={(page) => get_product_questions(page.selected + 1)}
            />
          )}
        </>
      )}
    </div>
  );
}

QuestionsAndAnswers.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(QuestionsAndAnswers);
