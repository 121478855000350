import React, { useState, useEffect } from "react";

//
import useCurrency from "../../hooks/useCurrency.js";

//
import moment from "jalali-moment";
import {
  Accordion,
  Card,
  Col,
  Row,
  Button,
  Collapse,
  Form,
} from "react-bootstrap";

//
import OrdersList from "./OrdersList.jsx";

//
import {
  getOrderList,
  getUserWallets,
  chargeUserWallet,
  setOrderDone,
} from "../../redux/features/user/actions.js";
import {
  getGateways,
  paymentWithWallet,
} from "../../redux/features/cart/actions.js";

//
import NumberFormat from "react-number-format";
import StepsNavbar from "../cart/StepsNavbar.jsx";

//
import { orderListSteps } from "../../constances.js";

//
import NothingToShow from "../globals/NothingToShow.jsx";
import BoxLoading from "../globals/BoxLoading.jsx";
import Pagination from "../globals/Pagination.jsx";
import PaymentGateWays from "../globals/PaymentGateWays.jsx";
import ReturnOrderModal from "./ReturnOrderModal.jsx";

function OrdersTab({ expiredOrders = false }) {
  const [currencyTitle] = useCurrency();

  const [orders, setOrders] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(0);

  const [boxloading, setBoxLoading] = useState(false);

  const [gateWays, setGateWays] = useState([]);

  const [returnedOrder, setReturnedOrder] = useState({
    id: 0,
    items: [],
  });
  const [showReturnedModal, setShowReturnedModal] = useState(false);

  const [walletOpen, setWalletOpen] = useState(false);
  const [wallets, setWallets] = useState([]);

  const [walletAmounts, setWalletAmounts] = useState({});
  const [walletPays, setWalletPays] = useState({});

  const convertStatusToStep = (status) => {
    if (status === "pending_payment") return 1;
    if (status === "paid") return 2;
    if (status === "processing" || status === "canceled") return 3;
    if (status === "sent") return 4;
    if (status === "delivered" || status === "approved") return 5;
    if (status === "done" || status === "checkout") return 6;
  };

  /**
   * Get Orders
   */
  const get_orders = async (page) => {
    // variable
    let params = new URLSearchParams();
    params.set("page", page);
    if (expiredOrders) {
      params.set("status", "expired");
    }

    // request
    await getOrderList(params).then((res) => {
      setOrders(res);
    });
  };

  const changeOrderStatusToPaid = (orderId) => {
    let orderList = { ...orders };

    for (let i = 0; i < orderList.data.length; i++) {
      const element = orderList.data[i];
      if (element.id == orderId) {
        element.status = "paid";

        for (let s = 0; s < element.orders.length; s++) {
          const sub = element.orders[s];
          sub.status = "paid";
        }
        break;
      }
    }

    setOrders(orderList);
  };

  const changeSubOrderStatus = (orderId, subOrderId, newStatus) => {
    let orderList = { ...orders };

    for (let i = 0; i < orderList.data.length; i++) {
      const element = orderList.data[i];
      if (element.id == orderId) {
        for (let s = 0; s < element.orders.length; s++) {
          const sub = element.orders[s];
          if (sub.id == subOrderId) {
            sub.status = newStatus;
            break;
          }
        }
        break;
      }
    }

    setOrders(orderList);
  };

  /**
   * Set Order Status To Done
   */
  const set_order_done = (orderId, subOrderId) => {
    setOrderDone(subOrderId).then((res) => {
      if (res) {
        changeSubOrderStatus(orderId, subOrderId, "done");
      }
    });
  };

  /**
   * Get Gateways
   */
  const get_gateways = async () => {
    if (selectedAddress) {
      setGateWays([]);

      let id = selectedAddress;

      // request
      await getGateways(id).then((res) => {
        if (res) {
          setGateWays(res);
        }
      });
    }
  };

  /**
   * Open Returned Modal
   */
  const openReturnedModal = (order_id, orderItems) => {
    setReturnedOrder({
      id: order_id,
      items: orderItems,
    });
    setShowReturnedModal(true);
  };

  const disablePay = (key, amount) => {
    if (
      !+walletAmounts[key].payAmount ||
      +walletAmounts[key].payAmount > amount
    )
      return true;

    return false;
  };

  const amountConvert = (mainKey, amountKey) => {
    if (+walletAmounts[mainKey][amountKey])
      return walletAmounts[mainKey][amountKey] * walletAmounts[mainKey].rate;
    return 0;
  };

  const handleAmounts = (e, mainKey, valueKey) => {
    let infos = { ...walletAmounts };
    infos[mainKey][valueKey] = e;

    setWalletAmounts(infos);
  };

  const setWalletPayAmount = (key) => {
    let infos = { ...walletPays };

    // variable
    const content = { ...walletAmounts[key] };

    if (infos[key]) {
      infos[key].payAmount = content.payAmount;
    } else {
      infos[key] = content;
    }

    setWalletPays(infos);
  };

  const totelWalletPayAmount = () => {
    return Object.keys(walletPays).reduce(function (previous, key) {
      return previous + +walletPays[key].payAmount * +walletPays[key].rate;
    }, 0);
  };

  const payWithWallet = async (orderId) => {
    let wallets = [];
    for (const [key, value] of Object.entries(walletPays)) {
      wallets.push({
        wallet_id: value.id,
        amount: +value.payAmount,
      });
    }

    const order_id = orderId;

    const content = {
      wallets: wallets,
    };

    // request
    await paymentWithWallet({ order_id, content }).then((res) => {
      if (res) {
        setWalletPays({});
        getWalletList();
        changeOrderStatusToPaid(orderId);
      }
    });
  };

  const chargeWallet = async (key) => {
    // variable
    const id = walletAmounts[key].id;
    let content = {
      amount: walletAmounts[key].chargeAmount,
    };

    // request
    await chargeUserWallet({ id, content }).then((res) => {
      if (res) {
        window.open(res.paymentUrl, "_blank", "noreferrer");
      }
    });
  };

  const getWalletList = async () => {
    await getUserWallets().then((res) => {
      let walletAmountInfos = {};
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        walletAmountInfos[element.currency.code] = {
          chargeAmount: "",
          payAmount: "",
          id: element.id,
          rate: Number(+element.currency.convert_rate)
            .toFixed(+element.currency.decimal_count)
            .replace(/\.?0+$/, ""),
        };
      }

      setWalletAmounts(walletAmountInfos);
      setWallets(res);
    });
  };

  const filteredWallets = (currencyId) => {
    return wallets.filter(
      (wallet) => wallet.currency_id == currencyId && +wallet.amount > 0
    );
  };

  useEffect(async () => {
    setBoxLoading(true);
    await get_orders(1);
    if (!expiredOrders) {
      await getWalletList();
    }
    setBoxLoading(false);

    // eslint-disable-next-line
  }, []);

  useEffect(async () => {
    if (!expiredOrders) {
      get_gateways();
    }

    // eslint-disable-next-line
  }, [selectedAddress]);

  return (
    <>
      <div className="panel-item-box no-padding">
        {orders.data && orders.data.length ? (
          <Accordion>
            {orders.data.map((item, index) => (
              <Accordion.Item eventKey={index} key={item.id}>
                <Accordion.Header
                  onClick={() => setSelectedAddress(item.customer_address.id)}
                >
                  <Row className="w-100 align-items-center">
                    <Col sm={3}>
                      <p className="my-1">کد سفارش : {item.id}</p>
                    </Col>
                    <Col sm={3}>
                      <p className="my-1">
                        مبلغ :
                        <NumberFormat
                          value={+item.final_price}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      </p>
                    </Col>
                    <Col sm={3}>
                      <p className="my-1">تعداد :{item.items.length}</p>
                    </Col>
                    <Col sm={3}>
                      <p className="my-1">
                        {moment(item.created_at, "YYYY/MM/DD")
                          .locale("fa")
                          .format("YYYY/MM/DD")}
                      </p>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body
                  className={expiredOrders ? "initial-padding" : ""}
                >
                  {item.orders && (
                    <>
                      {item.orders.map((sub) => (
                        <div className="suborder-item" key={sub.id}>
                          {!expiredOrders && (
                            <StepsNavbar
                              step={convertStatusToStep(sub.status)}
                              steps={orderListSteps}
                              status={sub.status}
                            />
                          )}

                          <OrdersList
                            items={sub.items}
                            date={sub.created_at}
                            swiper
                          />

                          {sub.status === "sent" && (
                            <h6
                              className="text-primary pointer d-inline-block mb-0 mt-3"
                              onClick={() => set_order_done(item.id, sub.id)}
                            >
                              سفارش به صورت سالم تحویل گرفته شد
                            </h6>
                          )}

                          {sub.tracking_code && (
                            <div className="pt-3">
                              <h6 className="mb-2">کد پیگیری پستی:</h6>
                              <p>{sub.tracking_code}</p>
                            </div>
                          )}

                          {sub.shipping && sub.shipping.length && (
                            <div className="shipping-methods">
                              <div className="delivery-alert">
                                <p className="title">طریقه ارسال</p>
                                {sub.shipping.map((shipping) => (
                                  <div className="method" key={shipping.id}>
                                    <div>
                                      <img
                                        src={shipping.shipping_method.icon}
                                        alt={
                                          shipping.shipping_method.description
                                        }
                                      />
                                      <span>
                                        {shipping.shipping_method.title}
                                      </span>
                                      <span className="mx-3">-</span>

                                      <NumberFormat
                                        value={+shipping.total_price}
                                        displayType="text"
                                        thousandSeparator={true}
                                        className="cart-item-price"
                                      />

                                      <span className="mx-1">
                                        {sub.currency.title}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}

                          {sub.description && (
                            <div className="pt-3">
                              <h6 className="mb-2">توضیح سفارش:</h6>
                              <p>{sub.description}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}

                  {item.status === "pending_payment" && (
                    <div className="pay-methods">
                      <Card className="dark-border">
                        <Card.Body>
                          {!!filteredWallets(item.currency.id).length && (
                            <div className="item wallets-box">
                              <Form.Check
                                label="استفاده از کیف پول"
                                type="checkbox"
                                checked={walletOpen}
                                onChange={() => setWalletOpen(!walletOpen)}
                              />

                              <Collapse in={walletOpen}>
                                <div id="wallet-collapse">
                                  {filteredWallets(item.currency.id).map(
                                    (wallet) => (
                                      <Row
                                        key={wallet.id}
                                        className="wallet-item"
                                      >
                                        <Col
                                          md="4"
                                          className="d-flex justify-content-between mb-3 mb-md-0"
                                        >
                                          <div>
                                            <p className="wallet-item-title">
                                              موجودی {wallet.currency.postfix}:{" "}
                                              <NumberFormat
                                                value={+wallet.amount}
                                                displayType="text"
                                                thousandSeparator={true}
                                                className="price text-bold"
                                              />{" "}
                                              {wallet.currency.title}
                                            </p>

                                            <small className="convert-amount">
                                              معادل{" "}
                                              <NumberFormat
                                                value={+wallet.convert_amount}
                                                displayType="text"
                                                thousandSeparator={true}
                                                className="price text-bold"
                                              />{" "}
                                              {currencyTitle()}
                                            </small>
                                          </div>

                                          <i
                                            className="fas fa-sync-alt refresh-icon pointer"
                                            onClick={() => getWalletList()}
                                          ></i>
                                        </Col>

                                        <Col
                                          md="4"
                                          className="d-flex align-items-start gap-2 mb-3 mb-md-0"
                                        >
                                          <div className="w-100">
                                            <Form.Control
                                              type="number"
                                              min="0.0000001"
                                              step="0.0000001"
                                              className="form-control-solid number"
                                              autoComplete="off"
                                              readOnly={
                                                wallet.currency.status ===
                                                "disable"
                                              }
                                              value={
                                                walletAmounts[
                                                  wallet.currency.code
                                                ].payAmount
                                              }
                                              onChange={(e) =>
                                                handleAmounts(
                                                  e.target.value,
                                                  wallet.currency.code,
                                                  "payAmount"
                                                )
                                              }
                                            />
                                            <small>
                                              معادل{" "}
                                              <NumberFormat
                                                value={amountConvert(
                                                  wallet.currency.code,
                                                  "payAmount"
                                                )}
                                                displayType="text"
                                                thousandSeparator={true}
                                              />{" "}
                                              {currencyTitle()}
                                            </small>
                                          </div>

                                          <Button
                                            variant="primary"
                                            className="max-content"
                                            disabled={disablePay(
                                              wallet.currency.code,
                                              wallet.amount
                                            )}
                                            onClick={() =>
                                              setWalletPayAmount(
                                                wallet.currency.code
                                              )
                                            }
                                          >
                                            اعمال شود
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  )}

                                  <Row>
                                    <Col md="8">
                                      <h5 className="mb-4 mt-3">
                                        مبلغ استفاده شده از کیف پول{" "}
                                        <NumberFormat
                                          value={totelWalletPayAmount()}
                                          displayType="text"
                                          thousandSeparator={true}
                                        />
                                      </h5>
                                    </Col>

                                    <Col md="4">
                                      <Button
                                        variant="primary"
                                        size="lg"
                                        className="w-100 pill"
                                        disabled={
                                          totelWalletPayAmount() <
                                          +item.final_price
                                        }
                                        onClick={() => payWithWallet(item.id)}
                                      >
                                        پرداخت
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Collapse>
                            </div>
                          )}

                          <PaymentGateWays
                            order_id={item.id}
                            gateWays={gateWays}
                            price={item.final_price}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}

                  {!expiredOrders && (
                    <p
                      className="text-danger pointer d-inline-block mt-4"
                      onClick={() => openReturnedModal(item.id, item.items)}
                    >
                      ثبت درخواست مرجوعی
                    </p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NothingToShow text="سفارشی برای نمایش وجود ندارد" />
        )}
      </div>

      {boxloading && <BoxLoading />}

      {orders.last_page && orders.last_page > 1 && (
        <Pagination
          currentPage={orders.current_page - 1}
          lastPage={orders.last_page}
          changePage={(page) => get_orders(page.selected + 1)}
        />
      )}

      <ReturnOrderModal
        show={showReturnedModal}
        setShow={setShowReturnedModal}
        items={returnedOrder.items}
        order_id={returnedOrder.id}
      />
    </>
  );
}

export default OrdersTab;
