import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
SwiperCore.use([Autoplay]);

function MostVisitedCategury({categories}) {
  const [items, setItems] = useState([])

  const set_category_leve_2 = () => {
    let array = []
    if (categories.length) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        if (category.children) {
          for (let i = 0; i < category.children.length; i++) {
            const subCat = category.children[i];
            if (subCat.cover_image != 'empty') {
              array.push(
                {
                  cover_image: subCat.cover_image,
                  id: subCat.id,
                  slug: subCat.slug,
                  title: subCat.title,
                }
              )
            }
            
          }
        }
      }

      setItems(array)
    }
  }

  useEffect(() => {
    set_category_leve_2()
  
  }, [categories])
  
  return (
    <div className="most-visited-swiper">
      <Swiper
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          400: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 3.2,
          },
          768: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 6,
          },
          1200: {
            slidesPerView: 7,
          },
          1400: {
            slidesPerView: 9,
          },
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <Link to={`/category/${item.id}`}>
              <img src={item.cover_image} alt="image" />
              <p className="truncate"> {item.title}</p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

MostVisitedCategury.propTypes = {
  topNavSwiperState: PropTypes.bool,
  categories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.mainReducer.categories
})

export default connect(mapStateToProps)(MostVisitedCategury);
