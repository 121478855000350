import React from "react";
import PropTypes from "prop-types";

//
import useCurrency from "../../hooks/useCurrency";

//
import moment from "jalali-moment";

//
import ImageLoading from "../../assets/img/product/LoadingProduct.gif";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

function QouteItem({ item, add_to_cart }) {
  const [currencyTitle] = useCurrency();

  const statusCalculator = (status) => {
    if (status === "pending_seller") return "در حال بررسی";
    if (status === "pending_customer") return "استعلام شده";
    if (status === "done") return "خریداری شده";
    if (status === "expired") return "منقظی شده";
  };
  return (
    <tr>
      <td>
        <img
          className="img-fluid"
          src={item.product.main_image}
          alt={item.product.title}
          onError={ImageLoading}
        />
      </td>
      <td>
        <p className="code d-flex ">
          {item.product.code}{" "}
          <small className="text-muted me-2">
            {moment(item.created_at, "YYYY/MM/DD")
              .locale("fa")
              .format("YYYY/MM/DD")}
          </small>
        </p>

        <Link
          to={`/product/${item.product.id}`}
          className="product-name text-muted"
        >
          {item.product.title}
        </Link>
      </td>

      <td className="text-center">{statusCalculator(item.status)}</td>

      <td>
        <p className="order-price d-flex align-items-center justify-content-center gap-1">
          {item.final_price ? (
            <>
              <span className="currency-toman">{currencyTitle()}</span>
              <h6 className="price-Separate">
                <NumberFormat
                  value={+item.final_price}
                  displayType="text"
                  thousandSeparator={true}
                  className="ms-1 text-bold text-black"
                />
              </h6>
            </>
          ) : (
            <>-</>
          )}
        </p>
      </td>

      <td>
        <p className="order-number">{+item.quantity} عدد</p>
      </td>

      <td>
        <p
          className={`text-primary buy-qoute ${
            item.status === "pending_customer" ? "active" : ""
          }`}
          onClick={() =>
            item.status === "pending_customer" ? add_to_cart(item.id) : ""
          }
        >
          خرید
        </p>
      </td>
    </tr>
  );
}

QouteItem.propTypes = {
  item: PropTypes.object.isRequired,
  add_to_cart: PropTypes.func.isRequired,
};

export default QouteItem;
