import React from "react";
import PropTypes from "prop-types";

//
// import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

function MainLoading({ configs }) {
  return (
    <div id="main-loading">
      {configs.LOADING && <img src={configs.LOADING} alt="logo" />}
      {/* <Spinner animation="border" variant="black" size="sm" /> */}
    </div>
  );
}

MainLoading.propTypes = {
  configs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  configs: state.mainReducer.configs,
});

export default connect(mapStateToProps)(MainLoading);
