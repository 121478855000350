import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//
import useCurrency from "../../hooks/useCurrency";

//
import NumberFormat from "react-number-format";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

//
import ReturnOrderDropzone from "./ReturnOrderDropzone";
import { sendReturnOrderRequest } from "../../redux/features/user/actions";

function ReturnOrderModal({ show, setShow, items, order_id }) {
  const [currencyTitle] = useCurrency();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [initialValues, setInitialValues] = useState([]);

  const set_order_items = () => {
    if (show && items.length) {
      let newItems = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        newItems.push({
          ...item,
          value: false,
        });
      }

      setOrderItems(newItems);
    }
  };

  const changeAttr = (index) => {
    let items = [...orderItems];

    items[index].value = !items[index].value;

    setOrderItems(items);
  };

  const set_seleceted_items = () => {
    let newItems = [];
    let initial = [];

    for (let i = 0; i < orderItems.length; i++) {
      const item = orderItems[i];
      if (item.value) {
        newItems.push(item);
        initial.push({
          order_item_id: item.id,
          maxQuantity: item.quantity,
          quantity: item.quantity,
          reason: "",
          files: [],
        });
      }
    }

    setInitialValues(initial);
    setSelectedItems(newItems);
    setStep(2);
  };

  const changeReason = (e, i) => {
    let value = e.target.value;
    let initial = [...initialValues];
    initial[i].reason = value;

    setInitialValues(initial);
  };

  const disable_step_1 = () => {
    let selectedItems = orderItems.filter((item) => item.value);

    if (!selectedItems.length) return true;

    return false;
  };

  const disable_step_2 = () => {
    let disable = false;

    for (let i = 0; i < initialValues.length; i++) {
      const element = initialValues[i];
      if (!element.reason) {
        disable = true;
      }
    }

    return disable;
  };

  useEffect(() => {
    set_order_items();

    // eslint-disable-next-line
  }, [show]);

  const updateFileArray = (e, i) => {
    let initial = [...initialValues];
    initial[i].files = e;

    setInitialValues(initial);
  };

  const changeCount = (type, index) => {
    let initial = [...initialValues];

    if (
      type === "plus" &&
      initial[index].maxQuantity > initial[index].quantity
    ) {
      initial[index].quantity = initial[index].quantity + 1;
      setInitialValues(initial);
    }
    if (type === "minus" && initial[index].quantity != 1) {
      initial[index].quantity = initial[index].quantity - 1;
      setInitialValues(initial);
    }
  };

  const send_return_order_request = () => {
    // start loading
    setLoading(true);

    // set variable
    let fd = new FormData();
    fd.append("order_id", order_id);

    for (let i = 0; i < initialValues.length; i++) {
      const element = initialValues[i];

      fd.append(`items[${i}][reason]`, element.reason);
      fd.append(`items[${i}][quantity]`, element.quantity);

      if (element.files.length) {
        fd.append(`items[${i}][image]`, element.files[0]);
      }

      fd.append(`items[${i}][order_item_id]`, element.order_item_id);
    }

    // request
    sendReturnOrderRequest(fd).then((res) => {
      if (res) {
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      fullscreen="md-down"
      id="return-modal"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton className="no-border"></Modal.Header>
      <Modal.Body className="pt-0">
        {step === 1 ? (
          <>
            <table className="product-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {orderItems.map((item, index) => (
                  <tr>
                    <td>
                      <Form.Check
                        key={item.id}
                        type="checkbox"
                        id={item.id}
                        checked={item.value}
                        onChange={() => changeAttr(index)}
                      />
                    </td>

                    <td className="text-end">
                      <div className="d-flex align-items-center">
                        <p className="cart-item-image">
                          <img
                            src={item.product.main_image}
                            alt={item.product.title}
                          />
                        </p>
                        <p className="cart-item-title">
                          {item.product.title}
                          {item.product_option && (
                            <small>
                              (
                              {Object.entries(
                                typeof item.product_option.options_summary ===
                                  "string"
                                  ? JSON.parse(
                                      item.product_option.options_summary
                                    )
                                  : item.product_option.options_summary
                              ).map(([key, value], index) => (
                                <span key={index}>
                                  {key}: {value}
                                </span>
                              ))}
                              )
                            </small>
                          )}
                        </p>
                      </div>
                    </td>

                    <td>
                      <p>{item.quantity} عدد</p>
                    </td>

                    <td>
                      <div className="d-flex align-items-center justify-content-end gap-1">
                        <small className="text-muted">{currencyTitle()}</small>
                        <p>
                          <NumberFormat
                            value={+item.final_price}
                            displayType="text"
                            thousandSeparator={true}
                            className="cart-item-price"
                          />
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Button
              variant="primary"
              className="d-block me-auto mt-3"
              disabled={disable_step_1()}
              onClick={() => set_seleceted_items()}
            >
              تایید و ادامه
            </Button>
          </>
        ) : (
          <>
            {selectedItems.map((item, index) => (
              <>
                <table className="product-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>

                      <td className="text-end">
                        <div className="d-flex align-items-center">
                          <p className="cart-item-image">
                            <img
                              src={item.product.main_image}
                              alt={item.product.title}
                            />
                          </p>
                          <p className="cart-item-title">
                            {item.product.title}

                            {!!item.product_instance.option_values.length && (
                              <small>
                                (
                                {item.product_instance.option_values.map(
                                  (option, index) => (
                                    <span key={index}>
                                      {option.option.title}:{" "}
                                      {option.option.name === "color"
                                        ? JSON.parse(option.value).name
                                        : option.value}
                                    </span>
                                  )
                                )}
                                )
                              </small>
                            )}
                          </p>
                        </div>
                      </td>

                      <td>
                        <div className="cart-item-count-box">
                          <div className="counter">
                            <div className="icon-hoder">
                              <i
                                className="fas fa-plus boxed-icon small"
                                onClick={() => changeCount("plus", index)}
                              ></i>
                            </div>
                            <span>{initialValues[index].quantity}</span>
                            <div className="icon-holder">
                              <i
                                className="fas fa-minus boxed-icon small"
                                onClick={() => changeCount("minus", index)}
                              ></i>
                            </div>
                          </div>
                          {item.product.packaging && (
                            <p className="mx-1">
                              <span>{item.product.packaging.title}</span>
                              <span className="mx-1">
                                {+item.product.packaging.stepper}
                              </span>
                              <span>
                                {item.product.packaging.measurement_title}
                              </span>
                            </p>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center justify-content-end gap-1">
                          <small className="text-muted">
                            {currencyTitle()}
                          </small>
                          <p>
                            <NumberFormat
                              value={
                                +item.unit_original_price *
                                initialValues[index].quantity
                              }
                              displayType="text"
                              thousandSeparator={true}
                              className="cart-item-price"
                            />
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Form.Group className="form-group">
                  <Form.Label className="bold">
                    توضیحات
                    <span className="text-primary me-1">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    type="text"
                    name="reason"
                    className="form-control-solid round red-focus no-resize"
                    onChange={(e) => changeReason(e, index)}
                    autoComplete="off"
                  />
                </Form.Group>

                <ReturnOrderDropzone
                  updateFileArray={updateFileArray}
                  index={index}
                />
              </>
            ))}

            <div className="mt-3 d-flex justify-content-end">
              <Button variant="white" onClick={() => setStep(1)}>
                بازگشت
              </Button>
              <Button
                variant="primary"
                disabled={disable_step_2()}
                onClick={() => send_return_order_request()}
              >
                {loading && (
                  <div className="spinner-holder">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
                ثبت درخواست
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

ReturnOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  order_id: PropTypes.number.isRequired,
};

export default ReturnOrderModal;
