import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";

//
import { Button, Col, Row } from "react-bootstrap";

//
import UnSuccess from "../assets/img/unSuccessfullPayment.png";
import Success from "../assets/img/successfullPayment.png";

const successText = "پرداخت موفق";
const failedText =
  "پرداخت ناموفق بوده است. در صورت وجود مشکل با پشتیبانی در تماس باشید";

function PaymentCallback(props) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState({
    status: 1,
    orderId: "",
  });

  const statusCovertor = (status) => {
    if (status == 1) return "منقضی شده";
    if (status == 2) return "کنسل شده";
    if (status == 10) return "پرداخت شده";
  };

  const statusColor = (status) => {
    if (status == 10) return "success";
      return "danger";
  }

  /**
   * Get Query From URL
   */
  const getQueryParams = () => {
    let queries = { ...query };
    queries.status = searchParams.get("status");
    queries.orderId = searchParams.get("orderId");
    setQuery(queries);
  };

  const backToHome = () => {
    const backPage = localStorage.getItem('paymentBackPage') || '/'
    navigate(backPage)
    localStorage.removeItem('paymentBackPage')
    localStorage.removeItem('paymentBackPageText')
  }

  const backPageText = () => {
    const backPageText = localStorage.getItem('paymentBackPageText') || 'صفحه اصلی'

    return backPageText
  }

  useEffect(() => {
    getQueryParams();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="payment-callback">
      <h5
        className={`mb-3 text-bold ${
          query.status == 10 ? "text-success" : "text-primary"
        }`}
      >
        {query.status == 10 ? "پرداخت موفق" : "پرداخت ناموفق"}
      </h5>

      <img
        src={query.status == 10 ? Success : UnSuccess}
        alt={query.status == 10 ? "پرداخت موفق" : "پرداخت ناموفق"}
      />

      <Row className="w-100 justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <div className="item">
            <span>کد سفارش : </span>
            <span>{query.orderId}</span>
          </div>

          <div className="item">
            <span>وضعیت: </span>
            <span className={`text-${statusColor(query.status)}`}>{statusCovertor(query.status)}</span>
          </div>
        </Col>
      </Row>

      <Button variant="outline-primary" className="pill" onClick={() => backToHome()}>
        بازگشت به {backPageText()} 
      </Button>
    </div>
  );
}

PaymentCallback.propTypes = {};

export default PaymentCallback;
